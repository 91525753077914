import { all, takeEvery, put,call } from 'redux-saga/effects';
import API from '../../utils/api';
import {resetPasswordFailure, resetPasswordSuccess} from "../action";
import {RESET_PASSWORD} from "../action/types";
import { toast } from 'react-toastify';

function* resetPasswordRequest(action ) {
    try {
        const res = yield API.post('reset-password',{
            email:action.payload.email,
            token:action.payload.token,
            password:action.payload.values.password
        })
        if(res.data.meta.code === 1){
            yield put(resetPasswordSuccess(res.data));
            yield call(action.payload.callback)
            toast.success(res.data.meta.message);
        }else if(res.data.meta.code === 0){
            yield put(resetPasswordFailure(res.data));
        }
    }catch (e) {
        yield put(resetPasswordFailure(e));
    }
}

export function* watchResetPassword() {
    yield takeEvery(RESET_PASSWORD, resetPasswordRequest);
}

export default function* rootSaga() {
    yield all([
        watchResetPassword()
    ]);
}
