import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getToken } from '../utils/helper';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
const isToken = !!getToken();

const PrivateRoute = ({ component: Component, ...rest }) => {
    const isAuthenticated = useSelector((state) => state.authReducer.isAuthenticated);

    return (
        <Route
            {...rest}
            render={(props) =>
                isAuthenticated || isToken ? (
                    <>
                        <Component {...props} />
                    </>
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
};

PrivateRoute.propTypes = {
    component: PropTypes.object,
    rest: PropTypes.object,
};
export default PrivateRoute;
