import React, { memo } from 'react'
import LogoWhite from '../../assets/images/logo-white.svg'
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

const Footer = memo(({ location }) => {
  let currentDate = moment().format('yyyy');
  
  const footerClassName = location.pathname === '/login' ||
  location.pathname === '/forgot-password' ||
  location.pathname === '/reset-password' ? 'd-none' : 'footer-main py-5'
  return (
    <>
      <section className={footerClassName}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-4 text-center text-md-left  align-self-center">
              <img src={LogoWhite} alt="logo" className='img-fluid footer-logo'/>
            </div>
            <div className="col-12 col-md-3 text-center text-md-left align-self-center">
              <p className="f-16 m-0 color-white"><b>Contact<br/><a className="color-white"
                href="mailto:database@onlineinfluence.com">database@onlineinfluence.com</a></b></p>
            </div>
            <div className="col-12 col-md-4 text-center text-md-left mx-auto mt-3 mt-md-0  align-self-center">
              <p className='mb-0 f-16 d-inline-block'>Copyright {currentDate} - Online Influence Institute B.V.</p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
  
});

Footer.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  })
};
export default withRouter(Footer);
