import {
  CHECK_EXAMPLE_LIST,
  CHECK_EXAMPLE_LIST_FAILURE,
  CHECK_EXAMPLE_LIST_LOAD_MORE,
  CHECK_EXAMPLE_LIST_SUCCESS,
  EXAMPLE_DETAIL,
  EXAMPLE_DETAIL_FAILURE,
  EXAMPLE_DETAIL_SUCCESS,
  EXAMPLE_LIST,
  EXAMPLE_LIST_FAILURE,
  EXAMPLE_LIST_LOAD_MORE,
  EXAMPLE_LIST_SUCCESS,
  RESET_EXAMPLE_LIST,
  UPDATE_EXAMPLE_DETAIL,
  UPDATE_EXAMPLE_LIST,
  UPDATE_STATUS_EXAMPLE
} from './types';


export const exampleList = (payload) => ({
  type: EXAMPLE_LIST,
  payload
});

export const exampleListSuccess = (payload) => ({
  type: EXAMPLE_LIST_SUCCESS,
  payload
});

export const exampleListFailure = (payload) => ({
  type: EXAMPLE_LIST_FAILURE,
  payload
});

export const exampleDetail = (payload) => ({
  type: EXAMPLE_DETAIL,
  payload
});

export const exampleDetailSuccess = (payload) => ({
  type: EXAMPLE_DETAIL_SUCCESS,
  payload
});

export const exampleDetailFailure = (payload) => ({
  type: EXAMPLE_DETAIL_FAILURE,
  payload
});

export const UpdateExampleDetail = (payload) => ({
  type: UPDATE_EXAMPLE_DETAIL,
  payload
});

export const UpdateExampleList = (payload) => ({
  type: UPDATE_EXAMPLE_LIST,
  payload
});

export const resetExampleList = (payload) => ({
  type: RESET_EXAMPLE_LIST,
  payload
});

export const exampleListLoadMore = (payload) => ({
  type: EXAMPLE_LIST_LOAD_MORE,
  payload
})

export const checkExampleList = (payload) => ({
  type: CHECK_EXAMPLE_LIST,
  payload
});

export const checkExampleListLoadMore = (payload) => ({
  type: CHECK_EXAMPLE_LIST_LOAD_MORE,
  payload
})

export const checkExampleListSuccess = (payload) => ({
  type: CHECK_EXAMPLE_LIST_SUCCESS,
  payload
});

export const checkExampleListFailure = (payload) => ({
  type: CHECK_EXAMPLE_LIST_FAILURE,
  payload
});

export const UpdateStatusExample = (payload) => ({
  type: UPDATE_STATUS_EXAMPLE,
  payload
});
