import {
    ADD_COMMENT, ADD_COMMENT_SUCCESS,
    COMMENT_LIKE, COMMENT_LIKE_SUCCESS, COMMENT_LIKE_FAILURE,

} from './types';


export const addComment = (payload) => ({
    type: ADD_COMMENT,
    payload
});

export const AddCommentSuccess = (payload) => ({
    type: ADD_COMMENT_SUCCESS,
    payload
});

export const AddCommentFailure = (payload) => ({
    type: ADD_COMMENT_SUCCESS,
    payload
});

export const commentLike = (payload) =>({
    type: COMMENT_LIKE,
    payload
});
export const commentLikeSuccess = (payload) =>({
    type:  COMMENT_LIKE_SUCCESS,
    payload
});
export const commentLikeFailure = (payload) =>({
    type: COMMENT_LIKE_FAILURE,
    payload
})
