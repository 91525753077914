import { Formik } from 'formik';
import React, { memo, useEffect, useState } from 'react';
import * as Yup from 'yup';
import {
  FILE_MAX_SIZE,
  FILE_MAX_SIZE_MSG,
  FIRST_NAME_MAX_CHAR,
  FIRST_NAME_REQUIRED,
  INVALID_FILE_TYPE,
  LAST_NAME_MAX_CHAR,
  LAST_NAME_REQUIRED,
  validateImage,
} from '../../constants/validationMessage';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { getNotificationCount, profileUser } from '../../redux/action';
import Loader from '../../components/Loader/Loader';
import { ReactComponent as CloseBtn } from '../../assets/images/close-btn.svg';
import { useHistory, useLocation } from 'react-router';
import { getToken } from "../../utils/helper";

const UpdateProfileDetails = memo((props) => {
  const dispatch = useDispatch();
  const history = useHistory()
  const location = useLocation()
  const token = getToken();
  
  useEffect(() => {
    if (token) {
      dispatch(getNotificationCount())
    }
    
  }, [dispatch, token])
  
  const [videoIds, setVideoIds] = useState([]);
  
  const [form, setForm] = useState({
    image: '',
  });
  
  const [userImage, setFile] = useState('');
  const loading = useSelector((state) => state.profileDetailsReducer.loading);
  const deviceToken = useSelector(state => state.authReducer.deviceToken);
  
  
  const [initialValues, setInitialValues] = useState({
    firstName: '',
    lastName: '',
    accept_terms: false,
    profileImage: undefined,
  });
  
  const validationSchema = Yup.object({
    firstName: Yup.string().max(100, FIRST_NAME_MAX_CHAR).required(FIRST_NAME_REQUIRED),
    lastName: Yup.string().max(100, LAST_NAME_MAX_CHAR).required(LAST_NAME_REQUIRED),
    profileImage: Yup.mixed()
      .nullable()
      .notRequired()
      .test('fileSize', FILE_MAX_SIZE_MSG, (file) => {
        if (file && ['image/png', 'image/jpg', 'image/jpeg'].includes(file.type)) {
          return file.size <= FILE_MAX_SIZE;
        } else {
          return true;
        }
      })
      .test(
        'fileType',
        INVALID_FILE_TYPE,
        (file) => {
          if (file) {
            return ['image/png', 'image/jpg', 'image/jpeg'].includes(file.type)
          } else {
            return true
          }
        },
      ),
  });
  useEffect(() => {
    
    const userValue = {
      firstName: props.data.first_name,
      lastName: props.data.last_name,
    };
    setInitialValues(userValue);
    
  }, [props]);
  
  const changeTerms = (e) => {
    let values = { ...initialValues };
    values.accept_terms = e.target.checked;
    setInitialValues(values);
  }
  
  const onFileUpload = (e, name) => {
    e.preventDefault();
    let reader = new FileReader();
    let fileObj = e.target.files[0];
    
    if (fileObj) {
      const isInValidFile = validateImage(fileObj);
      setFile(fileObj);
      if (!isInValidFile) {
        reader.onloadend = () => {
          setForm({
            ...form,
            [name]: reader.result,
          });
        };
        reader.readAsDataURL(fileObj);
      }
    }
  };
  
  const onDone = () => {
    if (props.forcereload) {
      if (location.pathname.includes('login')) {
        window.location.href = '/';
      } else {
        window.location.reload(false);
      }
    } else {
      history.push('/')
    }
  }
  
  const handleSubmit = (values) => {
    dispatch(
      profileUser({
        values,
        userImage,
        deviceToken,
        callback: () => onDone()
      }),
    );
  };
  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      autoplay: 1,
    },
  };
  
  const _onReady = (event, videoId) => {
    const videoData = videoIds;
    videoData.push({
      target: event.target,
      videoId: videoId,
    });
    setVideoIds(videoData);
    event.target.pauseVideo();
  };
  
  const onPlay = (event, videoId) => {
    videoIds.forEach((value) => {
      if (value.videoId !== videoId) {
        value.target.pauseVideo();
      }
    });
  };
  
  return (
    <React.Fragment>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="update-profile-modal"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          {loading && <Loader/>}
          <div className="update-profile-main p-0 p-md-3">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h2 className="c-topol-title">
                    Welcome to the Online Infuence A/B Test Database!
                  </h2>
                  <p className="f-18 py-3">
                                        <span className="d-block">
                                            Looks like this is your first time here. Welcome!
                                        </span>
                    To get started, please fill in your details and upload a
                    profile picture.
                  </p>
                </div>
                <Formik
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                  validationSchema={
                    validationSchema
                  }
                  enableReinitialize={true}
                >
                  {(props) => {
                    const {
                      values,
                      touched,
                      errors,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue,
                    } = props;
                    
                    if (values.profileImage === '') {
                      delete errors.profileImage;
                    }
                    return (
                      <form onSubmit={handleSubmit} className="col-12">
                        <div className="row align-items-center">
                          <div className="col-12 col-md-8">
                            <div className="mb-4">
                              <label
                                htmlFor="firstName"
                                className="c-input-label"
                              >
                                First name
                              </label>
                              <input
                                id="firstName"
                                name="firstName"
                                placeholder="Enter your firstName"
                                type="text"
                                value={values.firstName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={
                                  errors.firstName &&
                                  touched.firstName
                                    ? 'c-input-style error'
                                    : 'c-input-style'
                                }
                              />
                              {errors.firstName &&
                                touched.firstName && (
                                  <div className="input-feedback text-red">
                                    {errors.firstName}
                                  </div>
                                )}
                            </div>
                            <div className="mb-4">
                              <label
                                htmlFor="lastName"
                                className="c-input-label"
                              >
                                Last name
                              </label>
                              <input
                                id="lastName"
                                name="lastName"
                                placeholder="Enter your lastName"
                                type="lastName"
                                value={values.lastName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={
                                  errors.lastName &&
                                  touched.lastName
                                    ? 'c-input-style error'
                                    : 'c-input-style'
                                }
                              />
                              {errors.lastName &&
                                touched.lastName && (
                                  <div className="input-feedback text-red">
                                    {errors.lastName}
                                  </div>
                                )}
                            </div>
                          </div>
                          
                          <div className="col-12 col-md-4 d-flex justify-content-center">
                            <div>
                              <div className="upload-profile-wrapper">
                                {form.image && errors.profileImage === undefined ? (
                                  <img
                                    src={form.image}
                                    alt="profileImage"
                                    className="img-fluid preview-image"
                                  />
                                ) : (
                                  <p className="upload-profile-text text-center">
                                    upload profile picture
                                  </p>
                                )}
                                
                                <input
                                  id="file"
                                  name="profileImage"
                                  type="file"
                                  onChange={(
                                    event,
                                  ) => {
                                    let reader = new FileReader();
                                    let fileObj =
                                      event.target
                                        .files[0];
                                    if (fileObj) {
                                      reader.onloadend = () => {
                                        setForm(
                                          {
                                            ...form,
                                            'image':
                                            reader.result,
                                          },
                                        );
                                      };
                                      reader.readAsDataURL(
                                        fileObj,
                                      );
                                      setFile(fileObj);
                                      setFieldValue(
                                        'profileImage',
                                        fileObj,
                                      );
                                      
                                      event.target.value = null;
                                    }
                                  }}
                                  className={
                                    errors.profileImage
                                      ? 'upload-profile-picture error'
                                      : 'upload-profile-picture'
                                  }
                                  accept=".png,.jpg,.jpeg"
                                />
                                
                                {form.image && errors.profileImage === undefined ? (
                                  <CloseBtn
                                    className="close-btn upload-profile-close-btn cp"
                                    onClick={() => {
                                      setForm({
                                        image: '',
                                      });
                                    }}
                                  />
                                ) : (
                                  ''
                                )}
                              </div>
                              {errors.profileImage ? (
                                <div className="input-feedback mt-3 text-center text-red">
                                  {errors.profileImage}
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                          
                          <div className="col-12 py-4">
                            <div className="hr-line"></div>
                          </div>
                          
                          <div className="col-12">
                            <p><input type="checkbox" id="terms" onChange={(e) => changeTerms(e)}/> <label
                              htmlFor="terms">Hereby
                              I declare I have read and accepted <a href="/docs/2021-10-26_General_Terms_of_Use.pdf"
                                                                    target="_blank">general terms
                                of use</a>, <a href="/docs/2021-10-26_Privacy_policy.pdf" target="_blank">privacy
                                policy</a> and <a href="/docs/2021-10-26_User_Policy_test_database_tool.pdf"
                                                  target="_blank">user
                                policy</a>.</label>
                            </p>
                          </div>
                          
                          {/*<div className="col-12">*/}
                          {/*  <p>*/}
                          {/*    To familiarize yourself with the*/}
                          {/*    mechanisms of the website, you can start*/}
                          {/*    by watching the instructional videos*/}
                          {/*    below. Good luck!*/}
                          {/*  </p>*/}
                          {/*</div>*/}
                          {/*<div className="first-login-yt-frame-box col-12">*/}
                          {/*  <div className="row">*/}
                          {/*    {notificationCount && notificationCount.user_type === 2 && videosmember.map((video, key) => (*/}
                          {/*      <div className="col-12 col-md-6">*/}
                          {/*        <div className="embed-responsive embed-responsive-16by9 mb-2">*/}
                          {/*          <iframe*/}
                          {/*            src={`https://player.vimeo.com/video/${video.videourl}&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}*/}
                          {/*            className="embed-responsive-item"*/}
                          {/*            frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen*/}
                          {/*            title={video.text}/>*/}
                          {/*        </div>*/}
                          {/*        <p className="f-14">*/}
                          {/*          {video.text}*/}
                          {/*        </p>*/}
                          {/*      </div>*/}
                          {/*    ))}*/}
                          {/*    {notificationCount && notificationCount.user_type === 1 && videosowner.map((video, key) => (*/}
                          {/*      <div className="col-12 col-md-6">*/}
                          {/*        <div className="embed-responsive embed-responsive-16by9 mb-2">*/}
                          {/*          <iframe*/}
                          {/*            src={`https://player.vimeo.com/video/${video.videourl}&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}*/}
                          {/*            className="embed-responsive-item"*/}
                          {/*            frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen*/}
                          {/*            title={video.text}/>*/}
                          {/*        </div>*/}
                          {/*        <p className="f-14">*/}
                          {/*          {video.text}*/}
                          {/*        </p>*/}
                          {/*      </div>*/}
                          {/*    ))}*/}
                          {/*  </div>*/}
                          {/*</div>*/}
                        </div>
                        
                        <div className="mt-3 text-center text-md-left">
                          <button
                            className="c-btn bg-red text-white"
                            type="submit"
                            disabled={
                              (values.firstName === '' &&
                                values.lastName === '' && values.accept_terms === 0) ||
                              errors.firstName ||
                              errors.lastName
                            }
                          >
                            Continue
                          </button>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
    ;
});

export default UpdateProfileDetails;
