import {ADD_EXAMPLE, ADD_EXAMPLE_SUCCESS,ADD_EXAMPLE_FAILURE,DELETE_EXAMPLE,DELETE_EXAMPLE_SUCCESS,DELETE_EXAMPLE_FAILURE} from "../action/types";


const INIT_STATE = {
    loading: false,
    data:[],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ADD_EXAMPLE:
            return { ...state, loading: true };
        case ADD_EXAMPLE_SUCCESS:
            return { ...state, loading: false,data:action.payload };
        case    ADD_EXAMPLE_FAILURE:
            return { ...state, loading: false,data:action.payload };
        default:
            return state;
        case DELETE_EXAMPLE:
            return {...state,loading: true};
        case DELETE_EXAMPLE_SUCCESS:
            return { ...state, loading: false,data:action.payload };
        case    DELETE_EXAMPLE_FAILURE:
            return { ...state, loading: false,data:action.payload };

    }
};
