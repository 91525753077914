import React, { memo } from 'react';

import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ToastContainer, Slide } from 'react-toastify';

import store from './redux/store';
import Routes from './routes';
import './libCss';

const MainApp = memo(() => {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('../firebase-messaging-sw.js')
            .then(function(registration) {
                console.log('Registration successful, scope is:', registration.scope);
            }).catch(function(err) {
            console.log('Service worker registration failed, error:', err);
        });
    }
  return (
    <Provider store={store()}>
      <Routes />
      <ToastContainer
        autoClose={3000}
        draggable={false}
        transition={Slide}
        closeButton={true}
        hideProgressBar={true}
        position='top-right'
        toastClassName='toast-notification'
      />
    </Provider>

  );
});

const rootElement = document.getElementById('root');

render(<MainApp />, rootElement);
