import { all, call, put, takeEvery } from 'redux-saga/effects';

import { toast } from 'react-toastify';

import API from '../../utils/api';
import {
  allUserListActiveSuccess,
  allUserListFailure,
  allUserListInActiveSuccess,
  bulkInviteFailure,
  bulkInviteSuccess,
  cancelInviteFailure,
  cancelInviteSuccess,
  inviteUserFailure,
  inviteUserSuccess,
  resetInviteUserFailure,
  resetInviteUserSuccess,
  updateUserFailure,
  updateUserList,
  updateUserSuccess,
  userListActiveSuccess,
  userListFailure,
  userListInActiveSuccess
} from '../action';
import {
  ALL_USER_LIST,
  CANCEL_INVITE,
  INVITE_BULK,
  RESET_INVITE, SEARCH_USER_LIST, SEARCH_USER_LIST_TEAM,
  USER_INVITE,
  USER_LIST,
  USER_UPDATE,
} from '../action/types';


function* UserListRequest(action) {
  try {
    const res = yield API.post('user-list', action.payload);
    if (res.data.meta.code === 1) {
      if (action.payload.is_type === '1') {
        yield put(userListActiveSuccess(res.data));
      } else {
        yield put(userListInActiveSuccess(res.data));
      }
      if (action.payload.type === 'update') {
        yield put(updateUserList(res.data))
      }
    } else if (res.data.meta.code === 0) {
      toast.error(res.data.meta.message);
      yield put(userListFailure(res.data));
    }
  } catch (e) {
    yield put(userListFailure(e));
  }
}

function* AllUserListRequest(action) {
  try {
    const res = yield API.post('all-user-list', action.payload);
    if (res.data.meta.code === 1) {
      if (action.payload.is_type === '1') {
        yield put(allUserListActiveSuccess(res.data));
      } else {
        yield put(allUserListInActiveSuccess(res.data));
      }
      // if (action.payload.type === 'update') {
      //   yield put(allUpdateUserList(res.data))
      // }
    } else if (res.data.meta.code === 0) {
      toast.error(res.data.meta.message);
      yield put(allUserListFailure(res.data));
    }
  } catch (e) {
    yield put(allUserListFailure(e));
  }
}

function* searchUserListRequest(action) {
  try {
    const res = yield API.post('search-user-list', action.payload);
    if (res.data.meta.code === 1) {
      if (action.payload.is_type === '1') {
        yield put(allUserListActiveSuccess(res.data));
      } else {
        yield put(allUserListInActiveSuccess(res.data));
      }
      // if (action.payload.type === 'update') {
      //   yield put(allUpdateUserList(res.data))
      // }
    } else if (res.data.meta.code === 0) {
      toast.error(res.data.meta.message);
      yield put(allUserListFailure(res.data));
    }
  } catch (e) {
    yield put(allUserListFailure(e));
  }
}

function* searchUserListTeamRequest(action) {
  try {
    const res = yield API.post('search-user-list-team', action.payload);
    if (res.data.meta.code === 1) {
      if (action.payload.is_type === '1') {
        yield put(userListActiveSuccess(res.data));
      } else {
        yield put(userListInActiveSuccess(res.data));
      }
      // if (action.payload.type === 'update') {
      //   yield put(allUpdateUserList(res.data))
      // }
    } else if (res.data.meta.code === 0) {
      toast.error(res.data.meta.message);
      yield put(userListFailure(res.data));
    }
  } catch (e) {
    yield put(userListFailure(e));
  }
}


function* updateUserRequest(action) {
  try {
    const formData = new FormData();
    formData.append('first_name', action.payload.values.firstName);
    formData.append('last_name', action.payload.values.lastName);
    formData.append('is_active', action.payload.values.userStatus);
    formData.append('user_type', action.payload.values.userRole);
    formData.append('user_id', action.payload.id);
    if (action.payload.profileImage) {
      formData.append('profile_photo', action.payload.profileImage);
    }
    
    const res = yield API.post('user-update', formData);
    if (res.data.meta.code === 1) {
      yield put(updateUserSuccess(res.data));
      toast.success(res.data.meta.message);
      yield call(action.payload.callback)
    } else if (res.data.meta.code === 0) {
      toast.error(res.data.meta.message);
      yield put(updateUserFailure(res.data));
    }
  } catch (e) {
    yield put(updateUserFailure(e));
  }
}


function* cancelInviteRequest(action) {
  
  try {
    const res = yield API.post(`cancel-invitation/${action.payload.id}`,);
    if (res.data.meta.code === 1) {
      yield put(cancelInviteSuccess(res.data));
      yield call(action.payload.callback)
      yield call(action.payload.callback1)
      toast.success(res.data.meta.message);
    } else if (res.data.meta.code === 0) {
      toast.error(res.data.meta.message);
      yield put(cancelInviteFailure(res.data));
    }
  } catch (e) {
    yield put(cancelInviteFailure(e));
  }
}


function* ResetInviteRequest(action) {
  
  try {
    const res = yield API.post(`resend-invitation/${action.payload.userId}`,);
    if (res.data.meta.code === 1) {
      yield put(resetInviteUserSuccess(res.data));
      toast.success(res.data.meta.message);
      yield call(action.payload.callback)
    } else if (res.data.meta.code === 0) {
      toast.error(res.data.meta.message);
      yield put(resetInviteUserFailure(res.data));
    }
  } catch (e) {
    yield put(cancelInviteFailure(e));
  }
}

function* inviteUserRequest(action) {
  const formData = new FormData();
  formData.append('first_name', action.payload.values.firstName);
  formData.append('last_name', action.payload.values.lastName);
  formData.append('email', action.payload.values.email);
  formData.append('user_type', action.payload.values.user_type);
  formData.append('team_id', action.payload.values.team_id);
  try {
    const res = yield API.post('user-invite', formData);
    if (res.data.meta.code === 1) {
      yield put(inviteUserSuccess(res.data));
      toast.success(res.data.meta.message);
      yield call(action.payload.callback)
      yield call(action.payload.callback1)
      yield call(action.payload.callback2)
    } else if (res.data.meta.code === 0) {
      toast.error(res.data.meta.message);
      yield put(inviteUserFailure(res.data));
    }
  } catch (e) {
    yield put(inviteUserFailure(e));
  }
}


function* inviteBulkRequest(action) {
  
  const formData = new FormData();
  formData.append('file', action.payload.file);
  try {
    const res = yield API.post('user-bulk-invite', formData);
    if (res.data.meta.code === 1) {
      yield put(bulkInviteSuccess(res.data));
      toast.success(res.data.meta.message);
      yield call(action.payload.callback1)
    } else if (res.data.meta.code === 0) {
      toast.error(res.data.meta.message);
      yield call(action.payload.callback2)
      yield put(bulkInviteFailure(res.data));
    }
  } catch (e) {
    yield put(bulkInviteFailure(e));
  }
}

export function* watchUserListAPI() {
  yield takeEvery(USER_LIST, UserListRequest);
  yield takeEvery(ALL_USER_LIST, AllUserListRequest);
  yield takeEvery(SEARCH_USER_LIST, searchUserListRequest);
  yield takeEvery(SEARCH_USER_LIST_TEAM, searchUserListTeamRequest);
  yield takeEvery(USER_UPDATE, updateUserRequest);
  yield takeEvery(CANCEL_INVITE, cancelInviteRequest);
  yield takeEvery(USER_INVITE, inviteUserRequest);
  yield takeEvery(RESET_INVITE, ResetInviteRequest);
  yield takeEvery(INVITE_BULK, inviteBulkRequest);
}

export default function* rootSaga() {
  yield all([watchUserListAPI()]);
}
