import {
    GET_NOTIFICATION,
    GET_NOTIFICATION_SUCCESS,
    GET_NOTIFICATION_FAILURE,
    DELETE_NOTIFICATION,
    DELETE_NOTIFICATION_SUCCESS,
    READ_NOTIFICATION,
    READ_NOTIFICATION_SUCCESS,
    READ_NOTIFICATION_FAILURE,
    GET_NOTIFICATION_UNREAD_COUNT,
    GET_NOTIFICATION_UNREAD_COUNT_SUCCESS,
    GET_NOTIFICATION_UNREAD_COUNT_FAILURE,
    UPDATE_NOTIFICATION,
    UPDATE_NOTIFICATION_SUCCESS,
    UPDATE_NOTIFICATION_FAILURE,
    RESET_NOTIFICATION,
    DELETE_NOTIFICATION_FAILURE,
    MARK_AS_READ_ALL,
    MARK_AS_READ_ALL_SUCCESS,
    MARK_AS_READ_ALL_FAILURE

} from '../action/types';

const INIT_STATE = {
    loading: false,
    data: {},
    count: null,
    notificationData:[],
    pagination:{},
    notificationReadData:{}
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_NOTIFICATION_UNREAD_COUNT:
            return { ...state, loading: true };
        case GET_NOTIFICATION_UNREAD_COUNT_SUCCESS:
            return { ...state, loading: false, count: action.payload };
        case GET_NOTIFICATION_UNREAD_COUNT_FAILURE:
            return { ...state, loading: false };


        case GET_NOTIFICATION:
            return { ...state, loading: true };
        case GET_NOTIFICATION_SUCCESS:
            return { ...state, loading: false,  notificationData: [...state.notificationData, ...action.payload.data],
                pagination: action.payload.meta,};
        case GET_NOTIFICATION_FAILURE:
            return {
                ...state,
                loading: false,
                notificationData: [...state.notificationData]
            };
        case RESET_NOTIFICATION:
            return {...state,loading: false,notificationData: []}


        case UPDATE_NOTIFICATION:
            return { ...state, loading: true };
        case UPDATE_NOTIFICATION_SUCCESS:
            return { ...state, loading: false,  notificationData: action.payload.data,
                pagination: action.payload.meta,};
        case UPDATE_NOTIFICATION_FAILURE:
            return { ...state, loading: false };


        case DELETE_NOTIFICATION:
            return { ...state, loading: true };
        case DELETE_NOTIFICATION_SUCCESS:
            return { ...state, loading: false, data: action.payload.id };
        case DELETE_NOTIFICATION_FAILURE:
            return { ...state, loading: false };

        case READ_NOTIFICATION:
            return { ...state, loading: true };
        case READ_NOTIFICATION_SUCCESS:
            return { ...state, loading: false, data: action.payload.notification_id };
        case READ_NOTIFICATION_FAILURE:
            return { ...state, loading: false };

        case MARK_AS_READ_ALL:
            return {...state,loading: true};
        case MARK_AS_READ_ALL_SUCCESS:
            return {...state,loading: false,notificationReadData: action.payload};
        case MARK_AS_READ_ALL_FAILURE:
            return {...state,loading: false}

        default:
            return state;
    }
};
