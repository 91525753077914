import {CATEGORY_LIST, CATEGORY_LIST_SUCCESS, CATEGORY_LIST_FAILURE} from "./types";


export const categoryList = () => ({
    type: CATEGORY_LIST
});

export const categoryListSuccess = (payload) => ({
    type: CATEGORY_LIST_SUCCESS,
    payload
});

export const categoryListFailure = () => ({
    type: CATEGORY_LIST_FAILURE,
});