import {all} from 'redux-saga/effects';
import homeSaga from './homeSaga';
import authSaga from './authSaga';
import forgotPasswordSaga from './forgotPasswordSaga';
import resetPasswrodSaga from './resetPasswrodSaga';
import profileDetailsSaga from './profileDetailsSaga'
import categorySaga from './categorySaga'
import addEditExampleSaga from './addEditExampleSaga'
import addEditExampleDetailSaga from './addEditExampleDetailSaga';
import exampleListSaga from './exampleListSaga';
import teamShareSaga from  './teamShareSaga';
import favouriteSaga from './favouriteSaga';
import commentSaga from './commentSaga';
import userListSaga from './userListSaga';
import teamSaga from './teamSaga';
import notificationSaga from './notificationSaga'

export default function* rootSaga() {
    yield all([
        homeSaga(),
        authSaga(),
        forgotPasswordSaga(),
        resetPasswrodSaga(),
        profileDetailsSaga(),
        categorySaga(),
        addEditExampleSaga(),
        addEditExampleDetailSaga(),
        exampleListSaga(),
        teamShareSaga(),
        favouriteSaga(),
        commentSaga(),
        userListSaga(),
        teamSaga(),
        notificationSaga(),
    ]);
}
