import {ADD_EXAMPLE_DETAIL, ADD_EXAMPLE_DETAIL_SUCCESS,ADD_EXAMPLE_DETAIL_FAILURE} from "../action/types";


const INIT_STATE = {
    loading: false,
    data:{},
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ADD_EXAMPLE_DETAIL:
            return { ...state, loading: true };
        case ADD_EXAMPLE_DETAIL_SUCCESS:
            return { ...state, loading: false,data:action.payload };
        case    ADD_EXAMPLE_DETAIL_FAILURE:
            return { ...state, loading: false,data:action.payload };
        default:
            return state;
    }
};
