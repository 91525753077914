import { all, takeEvery, put } from 'redux-saga/effects';

import {toast} from "react-toastify";

import API from '../../utils/api';
import {categoryListSuccess, categoryListFailure } from '../action';
import {CATEGORY_LIST} from "../action/types";

function* categoryListRequest() {
    try {

        const res = yield API.get('category-list')
        if(res.data.meta.code === 1){
            yield put(categoryListSuccess(res.data.data));

        }else if(res.data.meta.code === 0){
            toast.error(res.data.meta.message);
            yield put(categoryListFailure(res.data));
        }
    }catch (e) {
        yield put(categoryListFailure(e));
    }
}

export function* watchCategoryAPI() {
    yield takeEvery(CATEGORY_LIST, categoryListRequest);
}

export default function* rootSaga() {
    yield all([
        watchCategoryAPI()
    ]);
}
