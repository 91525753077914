import {
    GET_NOTIFICATION,
    GET_NOTIFICATION_FAILURE,
    GET_NOTIFICATION_SUCCESS,
    DELETE_NOTIFICATION,
    DELETE_NOTIFICATION_SUCCESS,
    DELETE_NOTIFICATION_FAILURE,
    READ_NOTIFICATION,
    READ_NOTIFICATION_SUCCESS,
    READ_NOTIFICATION_FAILURE,
    GET_NOTIFICATION_UNREAD_COUNT,
    GET_NOTIFICATION_UNREAD_COUNT_SUCCESS,
    GET_NOTIFICATION_UNREAD_COUNT_FAILURE,
    UPDATE_NOTIFICATION, UPDATE_NOTIFICATION_SUCCESS, UPDATE_NOTIFICATION_FAILURE, RESET_NOTIFICATION,
    MARK_AS_READ_ALL,
    MARK_AS_READ_ALL_SUCCESS,
    MARK_AS_READ_ALL_FAILURE
} from './types';

export const getNotificationCount = () => ({
    type: GET_NOTIFICATION_UNREAD_COUNT,
});

export const getNotificationCountSuccess = (payload) => ({
    type: GET_NOTIFICATION_UNREAD_COUNT_SUCCESS,
    payload,
});

export const getNotificationCountFailure = (payload) => ({
    type: GET_NOTIFICATION_UNREAD_COUNT_FAILURE,
    payload,
});


export const getNotification = (payload) => ({
    type: GET_NOTIFICATION,
    payload
});

export const getNotificationSuccess = (payload) => ({
    type: GET_NOTIFICATION_SUCCESS,
    payload,
});

export const getNotificationFailure = (payload) => ({
    type: GET_NOTIFICATION_FAILURE,
    payload,
});

export const resetNotification = (payload) => ({
    type: RESET_NOTIFICATION,
    payload,
});
export const updateNotification = (payload) => ({
    type: UPDATE_NOTIFICATION,
    payload
});

export const updateNotificationSuccess = (payload) => ({
    type: UPDATE_NOTIFICATION_SUCCESS,
    payload,
});

export const updateNotificationFailure = (payload) => ({
    type: UPDATE_NOTIFICATION_FAILURE,
    payload,
});



export const deleteNotification = (payload) => ({
    type: DELETE_NOTIFICATION,
    payload,
});

export const deleteNotificationSuccess = (payload) => ({
    type: DELETE_NOTIFICATION_SUCCESS,
    payload,
});

export const deleteNotificationFailure = (payload) => ({
    type: DELETE_NOTIFICATION_FAILURE,
    payload,
});

export const readNotification = (payload) => ({
    type: READ_NOTIFICATION,
    payload,
});

export const readNotificationSuccess = (payload) => ({
    type: READ_NOTIFICATION_SUCCESS,
    payload,
});

export const readNotificationFailure = (payload) => ({
    type: READ_NOTIFICATION_FAILURE,
    payload,
});

export const markAsReadAll = (payload) =>({
    type: MARK_AS_READ_ALL
});

export const markAsReadAllSuccess = (payload) =>({
    type: MARK_AS_READ_ALL_SUCCESS,
    payload
});

export const markAsReadAllFailure = (payload) =>({
    type:MARK_AS_READ_ALL_FAILURE,
    payload
})