import {
    ADD_COMMENT,
    ADD_COMMENT_SUCCESS,
    COMMENT_LIKE,
    ADD_COMMENT_FAILURE,
    COMMENT_LIKE_SUCCESS,
    COMMENT_LIKE_FAILURE,
} from '../action/types';


const INIT_STATE = {
    loading: false,
    data:[]
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ADD_COMMENT:
            return { ...state, loading: true,data: action.payload };
        case ADD_COMMENT_SUCCESS:
            return { ...state, loading: false,data:action.payload };
        case    ADD_COMMENT_FAILURE:
            return { ...state, loading: false,data:action.payload };

        case  COMMENT_LIKE:
            return {...state,loading: true,data: action.payload }
        case COMMENT_LIKE_SUCCESS:
            return {...state,loading: false,data:action.payload };
        case COMMENT_LIKE_FAILURE:
            return {...state,loading: false,data: action.payload};

        default:
            return state;
    }
};
