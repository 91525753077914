import { all, takeEvery, put,call } from 'redux-saga/effects';

import {toast} from "react-toastify";

import API from '../../utils/api';
import {favouriteUserSuccess, favouriteUserFailure } from '../action';
import {FAVOURITE_USER} from "../action/types";

function* favouriteRequest(action) {
    try {

        const res = yield API.post('favourite-un-favourite',{
            is_favourite:action.payload.is_favourite,
            examples_id:action.payload.examples_id,
        })
        if(res.data.meta.code === 1){
            yield put(favouriteUserSuccess(res.data));
            if(action.payload.type === 'account'){
              yield call(action.payload.callback)
                yield call(action.payload.callback1)
            }
        }else if(res.data.meta.code === 0){
            toast.error(res.data.meta.message);
            yield put(favouriteUserFailure(res.data));
        }
    }catch (e) {
        yield put(favouriteUserFailure(e));
    }
}

export function* watchFavouriteAPI() {
    yield takeEvery(FAVOURITE_USER, favouriteRequest);
}

export default function* rootSaga() {
    yield all([
        watchFavouriteAPI()
    ]);
}
