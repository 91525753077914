import {SET_FILTER, SET_FILTER_SUCCESS, SET_FILTER_FAILURE} from "../action/types";


const INIT_STATE = {
    loading: false,
    data:{},
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_FILTER:
            return { ...state, loading: true ,data:action.payload};
        case SET_FILTER_SUCCESS:
            return { ...state, loading: false,data:action.payload };
        case    SET_FILTER_FAILURE:
            return { ...state, loading: false,data:action.payload };
        default:
            return state;
    }
};
