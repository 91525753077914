import { all, call, put, takeEvery } from 'redux-saga/effects';

import { toast } from "react-toastify";

import API from '../../utils/api';
import { addEditExampleFailure, addEditExampleSuccess, deleteExampleFailure, deleteExampleSuccess, } from '../action';
import { ADD_EXAMPLE, DELETE_EXAMPLE } from "../action/types";

function* addEditExampleRequest(action) {
  try {
    const formData = new FormData();
    formData.append('title', action.payload.values.title);
    formData.append('description', action.payload.values.description);
    formData.append('visibility', action.payload.values.visibility);
    // formData.append('result_percentage' , action.payload.values.result_percentage);
    // formData.append('result_status' , action.payload.values.result_status);
    formData.append('results', JSON.stringify(action.payload.values.results));
    formData.append('video_url', action.payload.values.video_url);
    formData.append('video_description', action.payload.values.video_description);
    formData.append('tested_company', action.payload.values.tested_company);
    formData.append('live_time', action.payload.values.live_time);
    if (action.payload.values.tags) {
      formData.append('tags', JSON.stringify(action.payload.values.tags));
    }
    formData.append('category_id', action.payload.categoryArray.category_id.toString());
    if (action.payload.values.before_image && action.payload.values.after_image) {
      formData.append('before_image', action.payload.values.before_image);
      formData.append('after_image', action.payload.values.after_image);
    }
    if (action.payload.values.own_video_url) {
      formData.append('own_video_url', action.payload.values.own_video_url);
    }
    if (action.payload.values.visibility === 2) {
      formData.append('best_practise', action.payload.values.best_practise);
    }
    if (action.payload.values.tested_with) {
      formData.append('tested_with', action.payload.values.tested_with);
    }
    if (action.payload.id) {
      const res = yield API.post(`add-edit-example/${action.payload.id}`, formData)
      
      if (res.data.meta.code === 1) {
        yield put(addEditExampleSuccess(res.data.data));
        yield call(action.payload.callback)
        yield call(action.payload.callback1)
        toast.success(res.data.meta.message);
        
      } else if (res.data.meta.code === 0) {
        yield put(addEditExampleFailure(res.data));
        toast.error(res.data.meta.message)
      }
    } else {
      const res = yield API.post('add-edit-example', formData)
      if (res.data.meta.code === 1) {
        yield put(addEditExampleSuccess(res.data.data));
        yield call(action.payload.callback)
        toast.success(res.data.meta.message);
        
      } else if (res.data.meta.code === 0) {
        yield put(addEditExampleFailure(res.data));
        toast.error(res.data.meta.message)
      }
    }
    
    
  } catch (e) {
    yield put(addEditExampleFailure(e));
    toast.error(e);
  }
}

function* deleteExampleRequest(action) {
  try {
    
    const res = yield API.get(`example-delete/${action.payload.id}`)
    if (res.data.meta.code === 1) {
      yield put(deleteExampleSuccess(res.data.data));
      toast.success(res.data.meta.message);
      yield call(action.payload.callback)
    } else if (res.data.meta.code === 0) {
      toast.error(res.data.meta.message);
      yield put(deleteExampleFailure(res.data));
    }
  } catch (e) {
    yield put(deleteExampleFailure(e));
  }
}


export function* watchExampleAPI() {
  yield takeEvery(ADD_EXAMPLE, addEditExampleRequest);
}

export function* watchExampleDeleteAPI() {
  yield takeEvery(DELETE_EXAMPLE, deleteExampleRequest);
}

export default function* rootSaga() {
  yield all([
    watchExampleAPI(),
    watchExampleDeleteAPI()
  ]);
}
