import {
  TEAM_LIST,
  TEAM_LIST_ACTIVE_RESET,
  TEAM_LIST_ACTIVE_SUCCESS,
  TEAM_LIST_INACTIVE_RESET,
  TEAM_LIST_INACTIVE_SUCCESS,
  TEAM_SEARCH_LIST
} from '../action/types';

const INIT_STATE = {
  loading: false,
  inactiveTeams: [],
  activeTeams: [],
  activePagination: {},
  inactivePagination: {},
  data: {},
  teamUpdate: {},
  cancelData: {},
  cancelLoading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TEAM_LIST:
    case TEAM_SEARCH_LIST:
      return { ...state, loading: true };
    case TEAM_LIST_ACTIVE_SUCCESS:
      return {
        ...state,
        loading: false,
        activeTeams: [...state.activeTeams, ...action.payload.data],
        activePagination: action.payload.meta,
      };
    case TEAM_LIST_INACTIVE_SUCCESS:
      return {
        ...state,
        loading: false,
        inactiveTeams: [...state.inactiveTeams, ...action.payload.data],
        inactivePagination: action.payload.meta,
      };
    case TEAM_LIST_ACTIVE_RESET:
      return { ...state, loading: false, activeTeams: [], activePagination: {} }
    case TEAM_LIST_INACTIVE_RESET:
      return { ...state, loading: false, inactiveTeams: [] }
    // case USER_LIST_INACTIVE:
    //     return {
    //         ...state,
    //         loading: false,
    //         inactiveUser: [...state.inactiveUser, ...action.payload.data],
    //         inactivePagination: action.payload.meta,
    //     };
    // case USER_LIST_FAILURE:
    //     return {
    //         ...state,
    //         loading: false,
    //         activeUser: [...state.activeUser],
    //         inactiveUser: [...state.inactiveUser],
    //     };
    //
    // case USER_LIST_ACTIVE_RESET:
    //     return {...state,loading: false,activeUser: [],userUpdateData: {}}
    // case USER_LIST_INACTIVE_RESET:
    //     return {...state,loading: false,inactiveUser: [],userUpdateData: {}}
    // case USER_LIST_UPDATE_ACTIVE:
    //     const tempData = state.activeUser.filter(f=>f.user_id !== action.payload)
    //     return {
    //         ...state,
    //         loading: false,
    //         activeUser: tempData
    //     };
    //
    // case UPDATE_LIST_PROFILE:
    //     const updateProfileData = state.activeUser.map(item =>
    //         item.user_id === action.payload.user_id ? action.payload : item
    //     );
    //     return {...state,loading: false,activeUser: [...updateProfileData]}
    // case UPDATE_USER_LIST_ACTIVE_SUCCESS:
    //     return {...state,loading: false,activeUser: action.payload.data}
    // case USER_UPDATE:
    //     return {...state,userUpdateLoading: true}
    // case USER_UPDATE_SUCCESS:
    // return {...state,userUpdateLoading: false,userUpdateData: action.payload}
    // case USER_UPDATE_FAILURE:
    //     return {...state,userUpdateLoading: false,userUpdateData: action.payload}
    //
    // case CANCEL_INVITE:
    //     return {...state,cancelLoading: true}
    // case CANCEL_INVITE_SUCCESS:
    //     return {...state,cancelLoading: false,cancelData: action.payload}
    // case CANCEL_INVITE_FAILURE:
    //     return {...state,cancelLoading: false,cancelData: action.payload}
    //
    // case USER_INVITE:
    //     return {...state,loading: true}
    // case USER_INVITE_SUCCESS:
    //     return {...state,loading: false,data: action.payload}
    // case USER_INVITE_FAILURE:
    //     return {...state,loading: false,data: action.payload}
    //
    // case RESET_INVITE:
    //     return {...state,loading: true}
    // case RESET_INVITE_SUCCESS:
    //     return {...state,loading: false,data: action.payload}
    // case RESET_INVITE_FAILURE:
    //     return {...state,loading: false,data: action.payload}
    //
    // case INVITE_BULK:
    //     return {...state,loading: true,data: action.payload}
    // case INVITE_BULK_SUCCESS:
    //     return {...state,loading: false,data: action.payload}
    // case INVITE_BULK_FAILURE:
    //     return {...state,loading: false,data: action.payload}
    
    default:
      return state;
  }
};
