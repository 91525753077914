import {
   FORGOT_PASSWORD,
   FORGOT_PASSWORD_SUCCESS,
   FORGOT_PASSWORD_FAILURE,
    VERIFY_FORGOT_PASSWORD,
    VERIFY_FORGOT_PASSWORD_FAILURE,
    VERIFY_FORGOT_PASSWORD_SUCCESS

} from './types';

export const forgotPassword = (payload) => ({
    type:FORGOT_PASSWORD,
    payload
});

export const forgotPasswordSuccess = () => ({
    type:FORGOT_PASSWORD_SUCCESS

});

export const forgotPasswordFailure = () => ({
    type:FORGOT_PASSWORD_FAILURE
});

export const VerifyForgotPassword = (payload) => ({
    type:VERIFY_FORGOT_PASSWORD,
    payload
});

export const VerifyForgotPasswordSuccess = (payload) => ({
    type:VERIFY_FORGOT_PASSWORD_SUCCESS,
    payload
});

export const VerifyForgotPasswordFailure = (payload) => ({
    type:VERIFY_FORGOT_PASSWORD_FAILURE,
    payload
});
