import {
    GET_HOME_LIST,
    GET_HOME_LIST_SUCCESS,
    GET_HOME_LIST_FAILURE,
} from './types';

export const getHomeData = (payload) => ({
    type: GET_HOME_LIST,
    payload
});

export const getHomeDataSuccess = (payload) => ({
    type: GET_HOME_LIST_SUCCESS,
    payload
});

export const getHomeDataFailure = (payload) => ({
    type: GET_HOME_LIST_FAILURE,
    payload
});
