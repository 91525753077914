
import firebase from 'firebase/app';

import 'firebase/messaging'; // for cloud messaging

const config = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_APP_PROJECT_ID ,
    storageBucket: process.env.REACT_APP_FIREBASE_APP_STORAGE_BUCKET_ID ,
    messagingSenderId: process.env.REACT_APP_FIREBASE_APP_MESSAGING_SENDER_ID ,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
};

let messaging = null;
if (firebase.messaging.isSupported()) {
    const initializedFirebaseApp = firebase.initializeApp(config);
    messaging = initializedFirebaseApp.messaging();
}
export { messaging };
