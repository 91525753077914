import { all, takeEvery, put,call } from 'redux-saga/effects';
import API from '../../utils/api';
import {forgotPasswordFailure, forgotPasswordSuccess,VerifyForgotPasswordFailure,VerifyForgotPasswordSuccess} from "../action";
import {FORGOT_PASSWORD,VERIFY_FORGOT_PASSWORD} from "../action/types";
import { toast } from 'react-toastify';

function* forgotPasswordRequest(action) {
    try {
        const res = yield API.post('forgot-password',action.payload.values)
        if(res.data.meta.code === 1){
            yield put(forgotPasswordSuccess(res.data));
            toast.success(res.data.meta.message);
            yield call(action.payload.callback)
        }else if(res.data.meta.code === 0){
            yield put(forgotPasswordFailure(res.data));
            toast.error(res.data.meta.message);
        }
    }catch (e) {
        yield put(forgotPasswordFailure(e));
    }
}

function* VerifyForgotPasswordRequest(action) {
    try {
        const res = yield API.post('verify-forgot-password',action.payload)
        if(res.data.meta.code === 1){
            yield put(VerifyForgotPasswordSuccess(res.data));
            //toast.success(res.data.meta.message);
        }else if(res.data.meta.code === 0){
            yield put(VerifyForgotPasswordFailure(res.data));
            toast.error(res.data.meta.message);
        }
    }catch (e) {
        yield put(VerifyForgotPasswordFailure(e));
    }
}

export function* watchResetPassword() {
    yield takeEvery(FORGOT_PASSWORD, forgotPasswordRequest);
    yield takeEvery(VERIFY_FORGOT_PASSWORD,VerifyForgotPasswordRequest)
}

export default function* rootSaga() {
    yield all([
        watchResetPassword()
    ]);
}
