import axios from 'axios';
import { getToken, url } from './helper';
import { toast } from "react-toastify";

const downloadApi = axios.create({
  baseURL: url,
  responseType: 'blob', // important
});

downloadApi.interceptors.request.use(function (config) {
  if (getToken()) {
    config.headers.Authorization = `Bearer ${getToken()}`;
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});


downloadApi.interceptors.response.use((response) => {
  
  return response
}, function (error) {
  if (error.response) {
    if (error.response.status === 401) {
      toast.error(error.response.data.message)
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      setTimeout(() => {
        window.location.href = '/login';
      }, 1000)
      
      return Promise.reject(error);
    }
    if (error.response.status === 400) {
      toast.error(error.response.data.message)
    }
    
  }
  
  
  return Promise.reject(error.response);
});


export default downloadApi;
