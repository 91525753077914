import { all, takeEvery, put,call } from 'redux-saga/effects';

import {toast} from "react-toastify";

import API from '../../utils/api';
import {loginUserSuccess, loginUserFailure, updateAuthStatus,
    saveDeviceTokenSuccess,
    saveDeviceTokenFailure, saveDeviceToken } from '../action';
import {LOGIN_USER,SAVE_DEVICE_TOKEN} from "../action/types";
import { setToken,setUserdata } from '../../utils/helper';
import {webDeviceType} from '../../utils/helper';

function* loginUserRequest(action) {
    try {

        const res = yield API.post('login',action.payload.values)
        if(res.data.meta.code === 1){
            yield put(loginUserSuccess(res.data));
            yield call(setToken, res.data.meta.token);
            yield call(setUserdata,res.data.data)
            yield put(updateAuthStatus(true));
            if(res.data.data.is_profile_complete === 1 && res.data.data.accept_terms === 1){
                if(action.payload.deviceToken){
                    yield put(saveDeviceToken({
                        device_token: action.payload.deviceToken,
                        is_logout: 0,
                        callback: () => action.payload.callback1
                    }))
                }

                yield call(action.payload.callback1)
            }
            else {
                yield call(action.payload.callback2)
            }

        }else if(res.data.meta.code === 0){
            toast.error(res.data.meta.message);
            yield put(loginUserFailure(res.data));
        }
    }catch (e) {
        yield put(loginUserFailure(e));
        toast.error(e);
    }
}

function* saveDeviceTokenRequest(action) {
    try {
        const formData = new FormData();
        formData.append('is_logout' ,action.payload.is_logout);
        formData.append('device_type' ,webDeviceType);
        formData.append('device_token' ,action.payload.device_token);
        const res = yield API.post('device-token', formData);
        if(res.data.meta.code === 1){
            yield put(saveDeviceTokenSuccess());
            yield call(action.payload.callback)
        } else if(res.data.meta.code === 0) {
            yield put(saveDeviceTokenFailure());
            yield call(action.payload.callback)
        }
    }catch (e) {
        yield put(saveDeviceTokenFailure());
    }
}
export function* watchLoginUserAPI() {
    yield takeEvery(LOGIN_USER, loginUserRequest);
    yield takeEvery(SAVE_DEVICE_TOKEN, saveDeviceTokenRequest);
}

export default function* rootSaga() {
    yield all([
        watchLoginUserAPI()
    ]);
}
