import {ADD_EXAMPLE, ADD_EXAMPLE_SUCCESS, ADD_EXAMPLE_FAILURE,DELETE_EXAMPLE,DELETE_EXAMPLE_FAILURE,DELETE_EXAMPLE_SUCCESS} from "./types";


export const addEditExample = (payload) => ({
    type: ADD_EXAMPLE,
    payload
});

export const addEditExampleSuccess = () => ({
    type: ADD_EXAMPLE_SUCCESS
});

export const addEditExampleFailure = () => ({
    type: ADD_EXAMPLE_FAILURE,
});


export const deleteExample = (payload) =>({
    type:DELETE_EXAMPLE,
    payload
});

export const deleteExampleSuccess = (payload) =>({
    type:DELETE_EXAMPLE_SUCCESS,
    payload
});
export const deleteExampleFailure = (payload) =>({
    type:DELETE_EXAMPLE_FAILURE,
    payload
});

