import { all, call, put, takeEvery } from 'redux-saga/effects';

import { toast } from 'react-toastify';

import API from '../../utils/api';
import {
  deleteNotificationFailure,
  deleteNotificationSuccess,
  getNotificationCountFailure,
  getNotificationCountSuccess,
  getNotificationFailure,
  getNotificationSuccess,
  markAsReadAllFailure,
  markAsReadAllSuccess,
  readNotificationFailure,
  readNotificationSuccess,
  updateNotificationFailure,
  updateNotificationSuccess
} from '../action';
import {
  DELETE_NOTIFICATION,
  GET_NOTIFICATION,
  GET_NOTIFICATION_UNREAD_COUNT,
  MARK_AS_READ_ALL,
  READ_NOTIFICATION,
  UPDATE_NOTIFICATION
} from '../action/types';

function* getNotificationCountRequest() {
  try {
    const res = yield API.get('/unread-count');
    if (res.data.meta.code === 1) {
      yield put(getNotificationCountSuccess(res.data.data));
    } else if (res.data.meta.code === 2) {
      //team is not active
      yield put(getNotificationCountSuccess(res.data.data));
      if(!window.location.href.includes('no-access')) {
        window.location.href = "/no-access";
      }
    } else if (res.data.meta.code === 0) {
      yield put(getNotificationCountFailure(res.data));
    }
  } catch (e) {
    yield put(getNotificationCountFailure(e));
  }
}

function* getNotificationRequest(action) {
  try {
    const res = yield API.post('/notification-list', action.payload);
    if (res.data.meta.code === 1) {
      yield put(getNotificationSuccess(res.data));
    } else if (res.data.meta.code === 0) {
      toast.error(res.data.meta.message);
      yield put(getNotificationFailure(res.data));
    }
  } catch (e) {
    yield put(getNotificationFailure(e));
  }
}


function* updateNotificationRequest(action) {
  try {
    const res = yield API.post('/notification-list', action.payload);
    if (res.data.meta.code === 1) {
      yield put(updateNotificationSuccess(res.data));
    } else if (res.data.meta.code === 0) {
      toast.error(res.data.meta.message);
      yield put(updateNotificationFailure(res.data));
    }
  } catch (e) {
    yield put(updateNotificationFailure(e));
  }
}


function* deleteNotificationRequest(action) {
  try {
    const res = yield API.get(`/delete-notification/${action.payload.id}`);
    if (res.data.meta.code === 1) {
      yield put(deleteNotificationSuccess(res.data));
      yield call(action.payload.callback);
      yield call(action.payload.callback1);
      yield call(action.payload.callback2);
      toast.success(res.data.meta.message);
    } else if (res.data.meta.code === 0) {
      toast.error(res.data.meta.message);
      yield put(deleteNotificationFailure(res.data));
      yield call(action.payload.callback1);
      yield call(action.payload.callback2);
    }
  } catch (e) {
    yield put(deleteNotificationFailure(e));
  }
}

function* readNotificationRequest(action) {
  try {
    const res = yield API.post('/mark-as-read', action.payload);
    if (res.data.meta.code === 1) {
      yield put(readNotificationSuccess(res.data));
      yield call(action.payload.callback1);
    } else if (res.data.meta.code === 0) {
      yield put(readNotificationFailure(res.data));
      yield call(action.payload.callback1);
    }
  } catch (e) {
    yield put(readNotificationFailure(e));
  }
}

function* markAsAllNotificationRequest() {
  try {
    const res = yield API.post('/mark-as-read-all');
    if (res.data.meta.code === 1) {
      yield put(markAsReadAllSuccess(res.data));
    } else if (res.data.meta.code === 0) {
      yield put(markAsReadAllFailure(res.data));
    }
  } catch (e) {
    yield put(markAsReadAllFailure(e));
  }
}

export function* watchNotificationApi() {
  yield takeEvery(GET_NOTIFICATION_UNREAD_COUNT, getNotificationCountRequest);
  yield takeEvery(GET_NOTIFICATION, getNotificationRequest);
  yield takeEvery(UPDATE_NOTIFICATION, updateNotificationRequest);
  yield takeEvery(DELETE_NOTIFICATION, deleteNotificationRequest);
  yield takeEvery(READ_NOTIFICATION, readNotificationRequest);
  yield takeEvery(MARK_AS_READ_ALL, markAsAllNotificationRequest);
}

export default function* rootSaga() {
  yield all([watchNotificationApi()]);
}
