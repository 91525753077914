import {FAVOURITE_USER, FAVOURITE_USER_SUCCESS,FAVOURITE_USER_FAILURE,RESET_FAVOURITE} from "../action/types";


const INIT_STATE = {
    loading: false,
    data:{},
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case RESET_FAVOURITE:
            return {...state,loading: false,data: {}};
        case FAVOURITE_USER:
            return { ...state, loading: true };
        case FAVOURITE_USER_SUCCESS:
            return { ...state, loading: false,data:action.payload };
        case    FAVOURITE_USER_FAILURE:
            return { ...state, loading: false,data:action.payload };
        default:
            return state;
    }
};
