import { all, takeEvery, put,call } from 'redux-saga/effects';
import API from '../../utils/api';
import {commentLikeSuccess, commentLikeFailure,AddCommentSuccess,AddCommentFailure
} from '../action';
import {COMMENT_LIKE,ADD_COMMENT} from "../action/types";
import { toast } from 'react-toastify';

function* commentLikeRequest(action) {
    try {

        const res = yield API.post('comments-like',action.payload)
        if(res.data.meta.code === 1){
                yield put(commentLikeSuccess(res.data.data));
            yield call(action.payload.callback)
        }else if(res.data.meta.code === 0){
            yield put(commentLikeFailure(res.data));
        }
    }catch (e) {
        yield put(commentLikeFailure(e));
    }
}
function* addCommentRequest(action) {
    try {
        if(action.payload.comment_id){
            const res = yield API.post(`add-example-comment/${action.payload.id}`,{
                comment:action.payload.comment,
                comment_id:action.payload.comment_id
            })

            if(res.data.meta.code === 1){
                yield put(AddCommentSuccess(res.data.data));
                toast.success(res.data.meta.message);
                yield call(action.payload.callback)
                yield call(action.payload.callback1)
            }else if(res.data.meta.code === 0){
                yield put(AddCommentFailure(res.data));
                toast.success(res.data.meta.message);
            }
        }
        else {
            const res = yield API.post(`add-example-comment/${action.payload.id}`,{
                comment:action.payload.comment
            })
            if(res.data.meta.code === 1){
                yield put(AddCommentSuccess(res.data.data));
                toast.success(res.data.meta.message);
                yield call(action.payload.callback)
                yield call(action.payload.callback1)
            }else if(res.data.meta.code === 0){
                yield put(AddCommentFailure(res.data));
                toast.success(res.data.meta.message);
            }
        }

    }catch (e) {
        yield put(AddCommentFailure(e));
    }
}
export function* watchCommentAPI() {
    yield takeEvery(COMMENT_LIKE, commentLikeRequest);
    yield takeEvery(ADD_COMMENT, addCommentRequest);
}

export default function* rootSaga() {
    yield all([
        watchCommentAPI()
    ]);
}
