import moment from "moment";
export const getToken = () => localStorage.getItem('token');
export const setToken = token => localStorage.setItem('token', token);
export const removeToken = () => localStorage.removeItem('token');
export const setUserdata = (user) => localStorage.setItem('user', JSON.stringify(user));
export const getUserData = () => JSON.parse(localStorage.getItem('user'));
export const removeUserData= () => localStorage.removeItem('user');


export const url = process.env.REACT_APP_API_ENDPOINT;
export const NumberPress = (event) => {

    const pattern =/^[+-]?[0-9]*([\.][0-9]*)?$/
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
        event.preventDefault();
    }
}

export const checkFavorite = {
    isFavorite: 1,
    isNotFavorite: 0
}

export const badgeCount ={
    twoToFiveCount :'2-5 Examples',
    sixToTen: '2-5 Examples',
    eleven :'11-20 Examples',
    twenty:'21-49 Examples',
    fifty:'21-49 Examples',
}
export const badgeName={
    reviewer:'Reviewer',
    seniorReviewer:'Senior Reviewer',
    contributor:'Contributor',
    seniorContributor:'Senior Contributor',
    topContributor:'Top Contributor'
}

export const getFormatedDateWithoutTime = (date,dateFormatted,type) => {
    switch (type){
        case 'monthDate':
            return moment(date,dateFormatted).format('MMM D, YYYY');
        case 'datemonth':
            return moment(date,dateFormatted).local().format('D MMM YYYY');
        case 'withYear':
            moment.updateLocale('en', {
                relativeTime : {
                    future: "in %s",
                    past:   "%s ago",
                    s  : '1 s',
                    ss : '%d s',
                    m:  "1 m",
                    mm: "%d m",
                    h:  "1 h",
                    hh: "%d h",
                    d:  "1 d",
                    dd: "%d d",
                    w:  "1 w",
                    ww: "%d w",
                    M:  "1 mo",
                    MM: "%d mo",
                    y:  "1 y",
                    yy: "%d y"
                }
            });
            return moment.unix(date, dateFormatted).local().fromNow(true);
        case 'days':
            return moment.utc(date).local().fromNow()
        default:
            return type;
    }
}
export const perPage = 10
export const noDataMessage = 'No data found';
export const webDeviceType = 1;
