import {SET_FILTER, SET_FILTER_SUCCESS, SET_FILTER_FAILURE} from "./types";


export const setFilterData = (payload) => ({
    type: SET_FILTER,
    payload
});

export const setFilterSuccess = (payload) => ({
    type: SET_FILTER_SUCCESS,
    payload
});

export const setFilterFailure = (payload) => ({
    type: SET_FILTER_FAILURE,
    payload
});