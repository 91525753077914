import {
  ALL_USER_LIST,
  ALL_USER_LIST_ACTIVE_RESET,
  ALL_USER_LIST_ACTIVE_SUCCESS,
  ALL_USER_LIST_FAILURE,
  ALL_USER_LIST_INACTIVE,
  ALL_USER_LIST_INACTIVE_RESET,
  CANCEL_INVITE,
  CANCEL_INVITE_FAILURE,
  CANCEL_INVITE_SUCCESS,
  INVITE_BULK,
  INVITE_BULK_FAILURE,
  INVITE_BULK_SUCCESS,
  RESET_INVITE,
  RESET_INVITE_FAILURE,
  RESET_INVITE_SUCCESS,
  UPDATE_LIST_PROFILE,
  UPDATE_USER_LIST_ACTIVE_SUCCESS,
  USER_INVITE,
  USER_INVITE_FAILURE,
  USER_INVITE_SUCCESS,
  USER_LIST,
  USER_LIST_ACTIVE_RESET,
  USER_LIST_ACTIVE_SUCCESS,
  USER_LIST_FAILURE,
  USER_LIST_INACTIVE,
  USER_LIST_INACTIVE_RESET,
  USER_LIST_UPDATE_ACTIVE,
  USER_UPDATE,
  USER_UPDATE_FAILURE,
  USER_UPDATE_SUCCESS
} from '../action/types';

const INIT_STATE = {
  loading: false,
  inactiveUser: [],
  activeUser: [],
  activePagination: {},
  inactivePagination: {},
  userUpdateData: {},
  data: {},
  cancelData: {},
  cancelLoading: false,
  userUpdateLoading: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case USER_LIST:
      return { ...state, loading: true };
    case USER_LIST_ACTIVE_SUCCESS:
      return {
        ...state,
        loading: false,
        activeUser: [...state.activeUser, ...action.payload.data],
        activePagination: action.payload.meta,
      };
    case USER_LIST_INACTIVE:
      return {
        ...state,
        loading: false,
        inactiveUser: [...state.inactiveUser, ...action.payload.data],
        inactivePagination: action.payload.meta,
      };
    case USER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        activeUser: [...state.activeUser],
        inactiveUser: [...state.inactiveUser],
      };
    
    case USER_LIST_ACTIVE_RESET:
      return { ...state, loading: false, activeUser: [], userUpdateData: {} }
    case USER_LIST_INACTIVE_RESET:
      return { ...state, loading: false, inactiveUser: [], userUpdateData: {} }
    
    case ALL_USER_LIST:
      return { ...state, loading: true };
    case ALL_USER_LIST_ACTIVE_SUCCESS:
      return {
        ...state,
        loading: false,
        activeUser: [...state.activeUser, ...action.payload.data],
        activePagination: action.payload.meta,
      };
    case ALL_USER_LIST_INACTIVE:
      return {
        ...state,
        loading: false,
        inactiveUser: [...state.inactiveUser, ...action.payload.data],
        inactivePagination: action.payload.meta,
      };
    case ALL_USER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        activeUser: [...state.activeUser],
        inactiveUser: [...state.inactiveUser],
      };
    
    case ALL_USER_LIST_ACTIVE_RESET:
      return { ...state, loading: false, activeUser: [], userUpdateData: {} }
    case ALL_USER_LIST_INACTIVE_RESET:
      return { ...state, loading: false, inactiveUser: [], userUpdateData: {} }
    
    case USER_LIST_UPDATE_ACTIVE:
      const tempData = state.activeUser.filter(f => f.user_id !== action.payload)
      return {
        ...state,
        loading: false,
        activeUser: tempData
      };
    
    case UPDATE_LIST_PROFILE:
      const updateProfileData = state.activeUser.map(item =>
        item.user_id === action.payload.user_id ? action.payload : item
      );
      return { ...state, loading: false, activeUser: [...updateProfileData] }
    case UPDATE_USER_LIST_ACTIVE_SUCCESS:
      return { ...state, loading: false, activeUser: action.payload.data }
    case USER_UPDATE:
      return { ...state, userUpdateLoading: true }
    case USER_UPDATE_SUCCESS:
      return { ...state, userUpdateLoading: false, userUpdateData: action.payload }
    case USER_UPDATE_FAILURE:
      return { ...state, userUpdateLoading: false, userUpdateData: action.payload }
    
    case CANCEL_INVITE:
      return { ...state, cancelLoading: true }
    case CANCEL_INVITE_SUCCESS:
      return { ...state, cancelLoading: false, cancelData: action.payload }
    case CANCEL_INVITE_FAILURE:
      return { ...state, cancelLoading: false, cancelData: action.payload }
    
    case USER_INVITE:
      return { ...state, loading: true }
    case USER_INVITE_SUCCESS:
      return { ...state, loading: false, data: action.payload }
    case USER_INVITE_FAILURE:
      return { ...state, loading: false, data: action.payload }
    
    case RESET_INVITE:
      return { ...state, loading: true }
    case RESET_INVITE_SUCCESS:
      return { ...state, loading: false, data: action.payload }
    case RESET_INVITE_FAILURE:
      return { ...state, loading: false, data: action.payload }
    
    case INVITE_BULK:
      return { ...state, loading: true, data: action.payload }
    case INVITE_BULK_SUCCESS:
      return { ...state, loading: false, data: action.payload }
    case INVITE_BULK_FAILURE:
      return { ...state, loading: false, data: action.payload }
    
    default:
      return state;
  }
};
