// =====================LRF===========================

export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const UPDATE_AUTH_STATUS = 'UPDATE_AUTH_STATUS';
export const UPDATE_DEVICE_TOKEN = 'UPDATE_DEVICE_TOKEN';
export const SAVE_DEVICE_TOKEN = 'SAVE_DEVICE_TOKEN';
export const SAVE_DEVICE_TOKEN_SUCCESS = 'SAVE_DEVICE_TOKEN_SUCCESS';
export const SAVE_DEVICE_TOKEN_FAILURE = 'SAVE_DEVICE_TOKEN_FAILURE';

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export const VERIFY_FORGOT_PASSWORD = 'VERIFY_FORGOT_PASSWORD';
export const VERIFY_FORGOT_PASSWORD_SUCCESS = 'VERIFY_FORGOT_PASSWORD_SUCCESS';
export const VERIFY_FORGOT_PASSWORD_FAILURE = 'VERIFY_FORGOT_PASSWORD_FAILURE';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';
export const UPDATE_USER_DETAILS_SUCCESS = 'UPDATE_USER_DETAILS_SUCCESS';
export const UPDATE_USER_DETAILS_FAILURE = 'UPDATE_USER_DETAILS_FAILURE';


// =====================HOME===========================

export const GET_HOME_LIST = 'GET_HOME_LIST';
export const GET_HOME_LIST_SUCCESS = 'GET_HOME_LIST_SUCCESS';
export const GET_HOME_LIST_FAILURE = 'GET_HOME_LIST_FAILURE';

// =====================CATEGORY-LIST===========================

export const CATEGORY_LIST = 'CATEGORY_LIST';
export const CATEGORY_LIST_SUCCESS = 'CATEGORY_LIST_SUCCESS';
export const CATEGORY_LIST_FAILURE = 'CATEGORY_LIST_FAILURE';


// =====================ADDEDITEXAMPLE===========================

export const ADD_EXAMPLE = 'ADD_EXAMPLE';
export const ADD_EXAMPLE_SUCCESS = 'ADD_EXAMPLE_SUCCESS';
export const ADD_EXAMPLE_FAILURE = 'ADD_EXAMPLE_FAILURE';

export const ADD_EXAMPLE_DETAIL = 'ADD_EXAMPLE_DETAIL';
export const ADD_EXAMPLE_DETAIL_SUCCESS = 'ADD_EXAMPLE_DETAIL_SUCCESS';
export const ADD_EXAMPLE_DETAIL_FAILURE = 'ADD_EXAMPLE_DETAIL_FAILURE';

// =====================EXAMPLELIST===========================

export const EXAMPLE_LIST = 'EXAMPLE_LIST';
export const EXAMPLE_LIST_SUCCESS = 'EXAMPLE_LIST_SUCCESS';
export const EXAMPLE_LIST_FAILURE = 'EXAMPLE_LIST_FAILURE';
export const UPDATE_EXAMPLE_LIST = 'UPDATE_EXAMPLE_LIST';
export const RESET_EXAMPLE_LIST = 'RESET_EXAMPLE_LIST';
export const EXAMPLE_LIST_LOAD_MORE = 'EXAMPLE_LIST_LOAD_MORE';


export const EXAMPLE_DETAIL = 'EXAMPLE_DETAIL';
export const EXAMPLE_DETAIL_SUCCESS = 'EXAMPLE_DETAIL_SUCCESS';
export const EXAMPLE_DETAIL_FAILURE = 'EXAMPLE_DETAIL_FAILURE';
export const UPDATE_EXAMPLE_DETAIL = 'UPDATE_EXAMPLE_DETAIL';

export const CHECK_EXAMPLE_LIST = 'CHECK_EXAMPLE_LIST';
export const CHECK_EXAMPLE_LIST_LOAD_MORE = 'CHECK_EXAMPLE_LIST_LOAD_MORE';
export const CHECK_EXAMPLE_LIST_SUCCESS = 'CHECK_EXAMPLE_LIST_SUCCESS';
export const CHECK_EXAMPLE_LIST_FAILURE = 'CHECK_EXAMPLE_LIST_FAILURE';
export const UPDATE_STATUS_EXAMPLE = 'UPDATE_STATUS_EXAMPLE';


// =====================TEAMUSER===========================

export const TEAM_USER_LIST = 'TEAM_USER_LIST';
export const TEAM_USER_LIST_SUCCESS = 'TEAM_USER_LIST_SUCCESS';
export const TEAM_USER_LIST_FAILURE = 'TEAM_USER_LIST_FAILURE';

export const SHARE_EXAMPLE = 'SHARE_EXAMPLE';
export const SHARE_EXAMPLE_SUCCESS = 'SHARE_EXAMPLE_SUCCESS';
export const SHARE_EXAMPLE_FAILURE = 'SHARE_EXAMPLE_FAILURE';

// =====================TEAM===========================

export const TEAM_LIST = 'TEAM_LIST';
export const TEAM_SEARCH_LIST = 'TEAM_SEARCH_LIST';
export const TEAM_LIST_SUCCESS = 'TEAM_LIST_SUCCESS';
export const TEAM_LIST_ACTIVE_SUCCESS = 'TEAM_LIST_ACTIVE_SUCCESS';
export const TEAM_LIST_INACTIVE_SUCCESS = 'TEAM_LIST_INACTIVE_SUCCESS';
export const TEAM_LIST_FAILURE = 'TEAM_LIST_FAILURE';
export const TEAM_LIST_ACTIVE_RESET = 'TEAM_LIST_ACTIVE_RESET';
export const TEAM_LIST_INACTIVE_RESET = 'TEAM_LIST_INACTIVE_RESET';
export const UPDATE_LIST_TEAM = 'UPDATE_LIST_TEAM';

export const ADD_TEAM = 'ADD_TEAM';
export const ADD_TEAM_SUCCESS = 'ADD_TEAM_SUCCESS';
export const ADD_TEAM_FAILURE = 'ADD_TEAM_FAILURE';

export const EDIT_TEAM = 'EDIT_TEAM';
export const EDIT_TEAM_SUCCESS = 'EDIT_TEAM_SUCCESS';
export const EDIT_TEAM_FAILURE = 'EDIT_TEAM_FAILURE';

// =====================favourite===========================

export const FAVOURITE_USER = 'FAVOURITE_USER';
export const FAVOURITE_USER_SUCCESS = 'FAVOURITE_USER_SUCCESS';
export const FAVOURITE_USER_FAILURE = 'FAVOURITE_USER_FAILURE';
export const RESET_FAVOURITE = 'RESET_FAVOURITE';

// =====================COMMENT SECTION===========================
export const ADD_COMMENT = 'ADD_COMMENT';
export const ADD_COMMENT_SUCCESS = 'ADD_COMMENT_SUCCESS';
export const ADD_COMMENT_FAILURE = 'ADD_COMMENT_FAILURE';

export const COMMENT_LIKE = 'COMMENT_LIKE';
export const COMMENT_LIKE_SUCCESS = 'COMMENT_LIKE_SUCCESS';
export const COMMENT_LIKE_FAILURE = 'COMMENT_LIKE_FAILURE';

export const SET_FILTER = 'SET_FILTER';
export const SET_FILTER_SUCCESS = 'SET_FILTER_SUCCESS';
export const SET_FILTER_FAILURE = 'SET_FILTER_FAILURE';


// =====================DELETE EXAMPLE===========================
export const DELETE_EXAMPLE = 'DELETE_EXAMPLE';
export const DELETE_EXAMPLE_SUCCESS = 'DELETE_EXAMPLE_SUCCESS';
export const DELETE_EXAMPLE_FAILURE = 'DELETE_EXAMPLE_FAILURE';

// =====================PROFILE DETAIL===========================
export const PROFILE_DETAIL = 'PROFILE_DETAIL';
export const PROFILE_DETAIL_SUCCESS = 'PROFILE_DETAIL_SUCCESS';
export const PROFILE_DETAIL_FAILURE = 'PROFILE_DETAIL_FAILURE';


export const UPDATE_ACCOUNT_DETAIL = 'UPDATE_ACCOUNT_DETAIL';
export const UPDATE_ACCOUNT_DETAIL_SUCCESS = 'UPDATE_ACCOUNT_DETAIL_SUCCESS';
export const UPDATE_ACCOUNT_DETAIL_FAILURE = 'UPDATE_ACCOUNT_DETAIL_FAILURE';

// =====================CHANGE PASSWORD===========================

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';


//====================NOTIFICATION SECTION======================
export const GET_NOTIFICATION_UNREAD_COUNT = 'GET_NOTIFICATION_UNREAD_COUNT'
export const GET_NOTIFICATION_UNREAD_COUNT_SUCCESS = 'GET_NOTIFICATION_UNREAD_COUNT_SUCCESS'
export const GET_NOTIFICATION_UNREAD_COUNT_FAILURE = 'GET_NOTIFICATION_UNREAD_COUNT_FAILURE'

export const GET_NOTIFICATION = 'GET_NOTIFICATION'
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS'
export const GET_NOTIFICATION_FAILURE = 'GET_NOTIFICATION_FAILURE'
export const RESET_NOTIFICATION = 'RESET_NOTIFICATION'

export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION'
export const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS'
export const UPDATE_NOTIFICATION_FAILURE = 'UPDATE_NOTIFICATION_FAILURE'

export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION'
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS'
export const DELETE_NOTIFICATION_FAILURE = 'DELETE_NOTIFICATION_FAILURE'


export const READ_NOTIFICATION = 'READ_NOTIFICATION'
export const READ_NOTIFICATION_SUCCESS = 'READ_NOTIFICATION_SUCCESS'
export const READ_NOTIFICATION_FAILURE = 'READ_NOTIFICATION_FAILURE'


export const MARK_AS_READ_ALL = 'MARK_AS_READ_ALL'
export const MARK_AS_READ_ALL_SUCCESS = 'MARK_AS_READ_ALL_SUCCESS'
export const MARK_AS_READ_ALL_FAILURE = 'MARK_AS_READ_ALL_FAILURE'


// =====================USER LIST===========================

export const USER_LIST = 'USER_LIST';
export const USER_LIST_ACTIVE_SUCCESS = 'USER_LIST_ACTIVE_SUCCESS';
export const USER_LIST_FAILURE = 'USER_LIST_FAILURE';
export const USER_LIST_INACTIVE = 'USER_LIST_INACTIVE';
export const USER_LIST_INACTIVE_RESET = 'USER_LIST_INACTIVE_RESET';
export const USER_LIST_ACTIVE_RESET = 'USER_LIST_ACTIVE_RESET';

export const ALL_USER_LIST = 'ALL_USER_LIST';
export const SEARCH_USER_LIST = 'SEARCH_USER_LIST';
export const SEARCH_USER_LIST_TEAM = 'SEARCH_USER_LIST_TEAM';
export const ALL_USER_LIST_ACTIVE_SUCCESS = 'ALL_USER_LIST_ACTIVE_SUCCESS';
export const ALL_USER_LIST_FAILURE = 'ALL_USER_LIST_FAILURE';
export const ALL_USER_LIST_INACTIVE = 'ALL_USER_LIST_INACTIVE';
export const ALL_USER_LIST_INACTIVE_RESET = 'ALL_USER_LIST_INACTIVE_RESET';
export const ALL_USER_LIST_ACTIVE_RESET = 'ALL_USER_LIST_ACTIVE_RESET';

export const USER_LIST_UPDATE_ACTIVE = 'USER_LIST_UPDATE_ACTIVE';
export const UPDATE_LIST_PROFILE = 'UPDATE_LIST_PROFILE';

export const UPDATE_USER_LIST_ACTIVE_SUCCESS = 'UPDATE_USER_LIST_ACTIVE_SUCCESS';


export const USER_UPDATE = 'USER_UPDATE';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAILURE = 'USER_UPDATE_FAILURE';


export const CANCEL_INVITE = 'CANCEL_INVITE';
export const CANCEL_INVITE_SUCCESS = 'CANCEL_INVITE_SUCCESS';
export const CANCEL_INVITE_FAILURE = 'CANCEL_INVITE_FAILURE';

export const USER_INVITE = 'USER_INVITE';
export const USER_INVITE_SUCCESS = 'USER_INVITE_SUCCESS';
export const USER_INVITE_FAILURE = 'USER_INVITE_FAILURE';

export const RESET_INVITE = 'RESET_INVITE';
export const RESET_INVITE_SUCCESS = 'RESET_INVITE_SUCCESS';
export const RESET_INVITE_FAILURE = 'RESET_INVITE_FAILURE';


export const INVITE_BULK = 'INVITE_BULK';
export const INVITE_BULK_SUCCESS = 'INVITE_BULK_SUCCESS';
export const INVITE_BULK_FAILURE = 'INVITE_BULK_FAILURE';
