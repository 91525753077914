import React, {useEffect, useState} from "react";
import ScrollTopIcon from '../../assets/images/scroll-top.svg'

const ScrollTop = () => {
    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {
        // Button is displayed after scrolling for 500 pixels
        const toggleVisibility = () => {
            if (window.pageYOffset > 500) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener("scroll", toggleVisibility);

        return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    return (
        <div className="scroll-top-container  cursor-pointer">
            {isVisible && (
                <button onClick={scrollToTop}
                >
                    <img src={ScrollTopIcon} className='scroll-top-icon' alt="Scroll-top"/>
                </button>
                )}
        </div>
    );
}
export default ScrollTop;


