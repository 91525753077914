import {
  SHARE_EXAMPLE,
  SHARE_EXAMPLE_FAILURE,
  SHARE_EXAMPLE_SUCCESS,
  TEAM_USER_LIST,
  TEAM_USER_LIST_FAILURE,
  TEAM_USER_LIST_SUCCESS
} from "../action/types";

const INIT_STATE = {
  loading: false,
  data: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TEAM_USER_LIST:
      return { ...state, loading: true };
    case TEAM_USER_LIST_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case    TEAM_USER_LIST_FAILURE:
      return { ...state, loading: false, data: action.payload };
    case SHARE_EXAMPLE:
      return { ...state, loading: true };
    case SHARE_EXAMPLE_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case SHARE_EXAMPLE_FAILURE:
      return { ...state, loading: true };
    default:
      return state;
  }
};
