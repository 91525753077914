import {LOGIN_USER, LOGIN_USER_FAILURE, LOGIN_USER_SUCCESS,
    UPDATE_AUTH_STATUS,
    UPDATE_DEVICE_TOKEN,
    SAVE_DEVICE_TOKEN_FAILURE,
    SAVE_DEVICE_TOKEN,
    SAVE_DEVICE_TOKEN_SUCCESS

} from "./types";


export const loginUser = (payload) => ({
    type: LOGIN_USER,
    payload
});

export const loginUserSuccess = (payload) => ({
    type: LOGIN_USER_SUCCESS,
    payload
});

export const loginUserFailure = (payload) => ({
    type: LOGIN_USER_FAILURE,
    payload
});


export const updateAuthStatus = (payload) => ({
    type: UPDATE_AUTH_STATUS,
    payload
});

export const updateDeviceToken = (payload) => ({
    type: UPDATE_DEVICE_TOKEN,
    payload
});

export const saveDeviceToken = payload => ({
    type: SAVE_DEVICE_TOKEN,
    payload
});

export const saveDeviceTokenSuccess = () => ({
    type: SAVE_DEVICE_TOKEN_SUCCESS
});

export const saveDeviceTokenFailure = () => ({
    type: SAVE_DEVICE_TOKEN_FAILURE
});