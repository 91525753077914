import { all, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import API from '../../utils/api';
import {
    checkExampleListFailure,
    checkExampleListLoadMore, checkExampleListSuccess,
    exampleDetailFailure,
    exampleDetailSuccess,
    exampleListFailure,
    exampleListLoadMore,
    exampleListSuccess,
} from '../action';
import { CHECK_EXAMPLE_LIST, EXAMPLE_DETAIL, EXAMPLE_LIST, UPDATE_STATUS_EXAMPLE } from '../action/types';
import { toast } from "react-toastify";

const CancelToken = axios.CancelToken;
let cancel;

function* exampleListRequest(action) {
  try {
    cancel && cancel();
    const res = yield API.post('example-list', action.payload, {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
    if (res.data.meta.code === 1) {
      if (action.payload.loadMore) {
        yield put(exampleListLoadMore(res.data));
      } else {
        yield put(exampleListSuccess(res.data));
      }
    } else if (res.data.meta.code === 0) {
      yield put(exampleListFailure(res.data));
    }
  } catch (e) {
    yield put(exampleListFailure(e));
  }
}

function* checkExampleListRequest(action) {
  try {
    cancel && cancel();
    const res = yield API.post('check-example-list', action.payload, {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
    if (res.data.meta.code === 1) {
      if (action.payload.loadMore) {
        yield put(checkExampleListLoadMore(res.data));
      } else {
        yield put(checkExampleListSuccess(res.data));
      }
    } else if (res.data.meta.code === 0) {
      yield put(checkExampleListFailure(res.data));
    }
  } catch (e) {
    yield put(checkExampleListFailure(e));
  }
}

function* exampleDetailRequest(action) {
  try {
    const res = yield API.get(`example-detail/${action.payload.id}`);
    if (res.data.meta.code === 1) {
      yield put(exampleDetailSuccess(res.data.data));
    } else if (res.data.meta.code === 0) {
      yield put(exampleDetailFailure(res.data));
    }
  } catch (e) {
    yield put(exampleDetailFailure(e));
  }
}

function* exampleStatusUpdate(action) {
  try {
    const res = yield API.post(`update-example-status/${action.payload.id}`, action.payload);
    if (res.data.meta.code === 1) {
      yield put(exampleDetailSuccess(res.data.data));
      toast.success(res.data.meta.message);
    } else if (res.data.meta.code === 0) {
      yield put(exampleDetailFailure(res.data));
      toast.error(res.data.meta.message);
    }
  } catch (e) {
    yield put(exampleDetailFailure(e));
  }
}

export function* watchExampleListAPI() {
  yield takeEvery(EXAMPLE_LIST, exampleListRequest);
  yield takeEvery(EXAMPLE_DETAIL, exampleDetailRequest);
  yield takeEvery(CHECK_EXAMPLE_LIST, checkExampleListRequest);
  yield takeEvery(UPDATE_STATUS_EXAMPLE, exampleStatusUpdate);
}

export default function* rootSaga() {
  yield all([watchExampleListAPI()]);
}
