import {FAVOURITE_USER, FAVOURITE_USER_SUCCESS, FAVOURITE_USER_FAILURE,RESET_FAVOURITE} from "./types";


export const favouriteUser = (payload) => ({
    type: FAVOURITE_USER,
    payload
});

export const favouriteUserSuccess = (payload) => ({
    type: FAVOURITE_USER_SUCCESS,
    payload
});

export const favouriteUserFailure = (payload) => ({
    type: FAVOURITE_USER_FAILURE,
    payload
});
export const resetFavouriteData = (payload) =>({
    type:RESET_FAVOURITE,
    payload
})