import {LOGIN_USER, LOGIN_USER_SUCCESS,LOGIN_USER_FAILURE,
    UPDATE_AUTH_STATUS, UPDATE_DEVICE_TOKEN, SAVE_DEVICE_TOKEN, SAVE_DEVICE_TOKEN_SUCCESS, SAVE_DEVICE_TOKEN_FAILURE
} from "../action/types";
import {getToken} from '../../utils/helper';

const INIT_STATE = {
    loading: false,
    data:{},
    isAuthenticated: (getToken() !== null && getToken() !== ''),
    deviceToken: null,
    authData:{}
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return { ...state, loading: true };
        case LOGIN_USER_SUCCESS:
            return { ...state, loading: false,authData:action.payload };
        case    LOGIN_USER_FAILURE:
            return { ...state, loading: false };
        case UPDATE_AUTH_STATUS:
            return { ...state, isAuthenticated: action.payload };
        case SAVE_DEVICE_TOKEN:
            return { ...state, loading: true };
        case SAVE_DEVICE_TOKEN_SUCCESS:
            return { ...state, loading: false };
        case SAVE_DEVICE_TOKEN_FAILURE:
            return { ...state, loading: false };
        case UPDATE_DEVICE_TOKEN:
            return { ...state, deviceToken: action.payload };
        default:
            return state;
    }
};
