import {
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_FAILURE,
    FORGOT_PASSWORD_SUCCESS,
    VERIFY_FORGOT_PASSWORD, VERIFY_FORGOT_PASSWORD_FAILURE, VERIFY_FORGOT_PASSWORD_SUCCESS,
} from '../action/types';


const INIT_STATE = {
    loading: false,
    VerifyForgotPasswordData:{}
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FORGOT_PASSWORD:
            return { ...state, loading: true };
        case FORGOT_PASSWORD_SUCCESS:
            return { ...state, loading: false };
        case FORGOT_PASSWORD_FAILURE:
            return { ...state, loading: false };
        case VERIFY_FORGOT_PASSWORD:
            return { ...state, loading: true };
        case VERIFY_FORGOT_PASSWORD_SUCCESS:
            return { ...state, loading: false,VerifyForgotPasswordData:action.payload.meta };
        case VERIFY_FORGOT_PASSWORD_FAILURE:
            return { ...state, loading: false };
        default:
            return state;
    }
};
