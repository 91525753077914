import { combineReducers } from 'redux';
import homeReducer from './homeReducer';
import authReducer from './authReducer';
import forgotPasswordReducer from './forgotPasswordReducer';
import resetPasswordReducer from './resetPasswordReducer';
import profileDetailsReducer from './profileDetailsReducer';
import categoryReducer from './categoryReducer';
import addEditExampleReducer from './addEditExampleReducer';
import addExampleDetailReducer from './addExampleDetailReducer';
import exampleListReducer from './exampleListReducer';
import TeamAndShare from './TeamAndShare';
import favouriteReducer from './favouriteReducer';
import commentReducer from './commentReducer';
import setFilterReducer from './setFilterReducer';
import userListReducer from './userListReducer';
import teamListReducer from './teamListReducer';
import notificationReducer from "./notificationReducer";

const appReducer = combineReducers({
  homeReducer,
  authReducer,
  forgotPasswordReducer,
  resetPasswordReducer,
  profileDetailsReducer,
  categoryReducer,
  addEditExampleReducer,
  addExampleDetailReducer,
  exampleListReducer,
  TeamAndShare,
  favouriteReducer,
  commentReducer,
  setFilterReducer,
  userListReducer,
  teamListReducer,
  notificationReducer,
});

const reducers = (state, action) => {
  return appReducer(state, action);
};

export default reducers;
