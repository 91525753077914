import React, { Suspense, memo, useEffect } from 'react';
import { Route, BrowserRouter as Router, Switch ,Redirect} from 'react-router-dom';

import ErrorBoundary from '../components/ErrorBoundary';
import RoutesFile from './Routes';
import PrivateRoute from "./PrivateRoute";
import Header from '../components/Common/Header';
import Footer from '../components/Common/Footer'
import ScrollTop from "../components/Common/ScrollTop";
import {messaging} from "../firebase";
import { useDispatch } from 'react-redux';
import {updateDeviceToken} from "../redux/action";

const Routes = memo((props) => {
  const dispatch = useDispatch();

  useEffect(()=>{
    if(messaging) {
      messaging.getToken({vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY}).then((currentToken) => {
        if (currentToken) {
          dispatch(updateDeviceToken(currentToken))
        }
      }).catch((err) => {
        //console.log('An error occurred while retrieving token. ', err);
      });

    }
  },[dispatch])

  return (
    <Router>
      <ErrorBoundary>
        <Suspense fallback={<></>}>
        <Header/>
        </Suspense>
        <Suspense fallback={<></>}>
          <div className="main-content">
            <Switch>
              {
                RoutesFile.map((itm, key) =>
                    itm.private ?
                      <PrivateRoute
                        key={key}
                        exact={itm.exact}
                        path={itm.path}
                        name={itm.name}
                        component={itm.component}
                      />
                      :
                      <Route
                        key={key}
                        exact={itm.exact}
                        path={itm.path}
                        name={itm.name}
                        component={itm.component}
                      />
                      )
              }
              <Redirect from='*' to='/404'/>
            </Switch>
          </div>
        </Suspense>
        <Suspense fallback={<></>}>
          <ScrollTop />
          <Footer/>
        </Suspense>
      </ErrorBoundary>
    </Router>
  );
});

export default Routes;
