import {ADD_EXAMPLE_DETAIL, ADD_EXAMPLE_DETAIL_SUCCESS, ADD_EXAMPLE_DETAIL_FAILURE} from "./types";


export const addExampleDetail = (payload) => ({
    type: ADD_EXAMPLE_DETAIL,
    payload
});

export const addExampleDetailSuccess = (payload) => ({
    type: ADD_EXAMPLE_DETAIL_SUCCESS,
    payload
});

export const addExampleDetailFailure = (payload) => ({
    type: ADD_EXAMPLE_DETAIL_FAILURE,
    payload
});