import React, { memo, useEffect, useRef, useState } from 'react'
import LogoWhite from '../../assets/images/logo-white.svg'
import InboxIcon from '../../assets/images/inbox-icon.svg'
import { ReactComponent as DownArrow } from "../../assets/images/down-arrow.svg";
import { useHistory, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getToken, getUserData, removeToken, removeUserData } from '../../utils/helper';
import { useDispatch, useSelector } from "react-redux";
import { getNotificationCount, saveDeviceToken, updateAuthStatus } from '../../redux/action';
import UpdateProfileDetails from "../../containers/LRF/UpdateProfileDetails";

const Header = memo(({ location }) => {
  const [showMenu, setShowMenu] = useState(false)
  const headerClassName = location.pathname === '/login' ||
  location.pathname === '/forgot-password' ||
  location.pathname === '/reset-password' ? 'd-none' : 'header-main py-3 py-md-4'
  const dispatch = useDispatch()
  const history = useHistory();
  const menuDiv = useRef();
  const headerProfileDiv = useRef()
  const notificationCount = useSelector(state => state.notificationReducer.count)
  const deviceToken = useSelector(state => state.authReducer.deviceToken);
  const profileData = useSelector(state => state.profileDetailsReducer.updateProfileData);
  const authStatus = useSelector(state => state.authReducer.authData);
  const [profileImage, setProfileImage] = useState('');
  const [showProfilePopup, setShowProfilePopup] = useState(false);
  const token = getToken()
  
  useEffect((e) => {
    function handleClickOutside(e) {
      if (menuDiv.current && !menuDiv.current.contains(e.target) && headerProfileDiv.current && !headerProfileDiv.current.contains(e.target)) {
        setShowMenu(false)
      }
    }
    
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuDiv]);
  
  useEffect(() => {
    if (token) {
      dispatch(getNotificationCount())
    }
    
  }, [location, dispatch, token])
  
  const logOut = (e) => {
    if (deviceToken) {
      dispatch(saveDeviceToken({
        device_token: deviceToken,
        is_logout: 1,
        callback: () => {
          removeToken();
          history.push('/login')
        }
      }));
    } else {
      history.push('/login')
      removeToken();
      removeUserData();
    }
    dispatch(updateAuthStatus(false))
  }
  
  useEffect(() => {
    if ((profileData.data && profileData.meta.code === 1)) {
      setProfileImage(profileData && profileData.data.profile_photo + '?w=50')
    }
    
  }, [profileData])
  
  useEffect(() => {
    if (authStatus.data && authStatus.meta.code === 1) {
      setProfileImage(authStatus && authStatus.data.profile_photo + '?w=50')
    }
    
  }, [authStatus])
  
  
  useEffect(() => {
    if (getUserData() && getUserData().profile_photo !== profileImage) {
      setProfileImage(notificationCount && notificationCount.image + '?w=50')
    }
    if (notificationCount) {
      if (notificationCount.is_profile_complete === 0 || notificationCount.accept_terms === 0) {
        setShowProfilePopup(true);
      }
    }
  }, [notificationCount])
  
  return (
    <>
      {showProfilePopup &&
        <UpdateProfileDetails show={showProfilePopup} onHide={() => setShowProfilePopup(false)} forcereload={true}
                              device={deviceToken} data={getUserData()}/>}
      <section className={headerClassName}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-between align-items-center">
                <div className="header-left " onClick={() => history.push('/')}>
                  <img className='mr-3 header-logo  mr-md-5 img-fluid cp ' src={LogoWhite}
                       alt="logo"/>
                  <span className='f-20 d-none d-md-inline-block'>A/B Test Database</span>
                </div>
                <div className="header-right d-inline-flex align-items-center">
                  <button className='pr-3 pr-md-4 py-md-2 header-inbox-btn'
                          onClick={() => {
                            history.push('/inbox')
                          }}>
                    <div
                      className="align-self-start align-self-md-center mt-2 mt-md-0 header-inbox">
                      <img src={InboxIcon} alt="inbox-icon"
                           className='img-fluid'/>
                      {notificationCount && notificationCount.badge_count > 0 ?
                        <div className="notification text-white">
                          {notificationCount.badge_count}
                        </div> : ""
                      }
                    </div>
                  </button>
                  <div
                    onClick={() => setShowMenu(!showMenu)}
                    className='header-user-info  ml-2 pl-2 pl-md-3  align-self-start align-self-md-center d-inline-flex align-items-center'>
                                        <span
                                          className='f-DM-sans f-16 mb-0 mr-3'>{notificationCount && notificationCount.name}</span>
                    <DownArrow className='down-arrow-header mr-3' fill='white'/>
                    <div className="header-user-img-box d-inline-flex" ref={headerProfileDiv}>
                      <img src={profileImage ? profileImage : InboxIcon}
                           className='header-user-img'
                           alt="userPhoto"/>
                    </div>
                    {
                      showMenu && (
                        <div className="menu" ref={menuDiv}>
                          <ul className='menu-ul mb-0'>
                            <li className='list-unstyled'>
                              <span className='menu-item' onClick={() => history.push('/account')}> Account </span>
                            </li>
                            <li>
                              <span className='menu-item' onClick={() => history.push('/instructionvideos')}>Instruction Videos</span>
                            </li>
                            {
                              notificationCount && notificationCount.user_type === 1 && notificationCount.is_admin &&
                              <li className='list-unstyled'>
                                                            <span className='menu-item'
                                                                  onClick={() => history.push('/invite-team')}> Invite Team
                                                                 </span>
                              </li>
                            }
                            {
                              notificationCount && notificationCount.user_type === 1 && notificationCount.is_admin &&
                              <li className='list-unstyled'>
                                    <span className='menu-item'
                                          onClick={() => history.push('/manage-teams')}> Manage Teams </span>
                              </li>
                            }
                            {
                              notificationCount && notificationCount.is_admin &&
                              <li className='list-unstyled'>
                                    <span className='menu-item'
                                          onClick={() => history.push('/manage-all-users')}> Manage All Users </span>
                              </li>
                            }
                            {
                              notificationCount && notificationCount.user_type === 1 && !notificationCount.is_individual_subscription &&
                              <li className='list-unstyled'>
                                                            <span className='menu-item'
                                                                  onClick={() => history.push('/invite-user')}> Invite Users
                                                                 </span>
                              </li>
                            }
                            {
                              notificationCount && notificationCount.user_type === 1 && !notificationCount.is_individual_subscription &&
                              <li className='list-unstyled'>
                                    <span className='menu-item'
                                          onClick={() => history.push('/manage-users')}> Manage Users </span>
                              </li>
                            }
                            
                            <li className='list-unstyled'>
                                <span className='menu-item'
                                      onClick={() => history.push('/example')}>Upload Example</span>
                            </li>
                            <li className='list-unstyled'>
                                <span className='menu-item'
                                      onClick={() => history.push('/my-examples')}>My Examples</span>
                            </li>
                            {notificationCount && notificationCount.user_type === 1 && !notificationCount.is_individual_subscription &&
                              <li className='list-unstyled'>
                                <span className='menu-item'
                                      onClick={() => history.push('/team-examples')}>Team Examples</span>
                              </li>}
                            {notificationCount && notificationCount.is_admin &&
                              <li className='list-unstyled'>
                                <span className='menu-item'
                                      onClick={() => history.push('/all-examples')}>All Examples</span>
                              </li>}
                            {notificationCount && notificationCount.is_admin &&
                              <li className='list-unstyled'>
                                <span className='menu-item'
                                      onClick={() => history.push('/check-examples')}>Check Examples</span>
                              </li>}
                            {
                              notificationCount && notificationCount.is_admin &&
                              <li className='list-unstyled'>
                                    <span className='menu-item'
                                          onClick={() => history.push('/export')}>Export</span>
                              </li>
                            }
                            <li className='list-unstyled py-2'>
                              <div className='hr-line'></div>
                            </li>
                            <li className='list-unstyled' onClick={() =>
                              logOut()
                            }>
                              <span className='menu-item sign-out'> Sign Out </span>
                            </li>
                          </ul>
                        </div>
                      )
                    }
                  </div>
                
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
  
});

Header.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  })
};
export default withRouter(Header);
