import {CATEGORY_LIST, CATEGORY_LIST_SUCCESS,CATEGORY_LIST_FAILURE} from "../action/types";


const INIT_STATE = {
    loading: false,
    data:[],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CATEGORY_LIST:
            return { ...state, loading: true };
        case CATEGORY_LIST_SUCCESS:
            return { ...state, loading: false,data:action.payload };
        case    CATEGORY_LIST_FAILURE:
            return { ...state, loading: false,data:action.payload };
        default:
            return state;
    }
};
