import React, { memo, useState } from 'react';
import UserDetailIcon from "../../assets/images/user-detail.svg";
import downloadApi from "../../utils/downloadapi";
import { useSelector } from "react-redux";

const Export = memo((props) => {
  let fileDownload = require('js-file-download');
  const notificationCount = useSelector(state => state.notificationReducer.count);
  const [start_date, setStartDate] = useState('');
  const [end_date, setEndDate] = useState('');
  const [dateError, setDateError] = useState(false);
  
  const exportExamples = (exportType = 'all') => {
    setDateError(false);
    if (exportType === 'date' && (start_date === '' || end_date === '')) {
      setDateError(true);
    } else {
      downloadApi.post('export/examples', {
        exportType: exportType,
        start_date: start_date,
        end_date: end_date
      }).then((response) => {
        fileDownload(response.data, 'examples.zip');
      });
    }
  }
  
  const exportAllUsers = () => {
    downloadApi.post('export/users').then((response) => {
      fileDownload(response.data, 'users.xlsx');
    });
  }
  
  return (
    <>
      <section className="example-banner py-5">
        <div className="container mb-5 mt-md-5 py-5">
          <div className="row align-items-center justify-content-between py-md-5">
            <div className="col-12 col-md-8 text-white">
              <div className="d-block">
                <h2 className="c-topol-title mb-md-0">
                  Export
                </h2>
              </div>
            </div>
            <div className="col-12 col-md-4 mt-3 mt-md-0 text-center text-md-left py-md-3">
              <img
                src={UserDetailIcon}
                className="user-detail-icon"
                alt="user-detail-icon"
              />
            </div>
          </div>
        </div>
        <div className="clip-banner"/>
      </section>
      {notificationCount && notificationCount.is_admin &&
        <section className='py-5 mb-5'>
          <div className='container mb-5 pb-5'>
            <div className='row'>
              <div className="col-md-4 mb-3">
                <a href="#" onClick={() => exportExamples('date')}>Export date</a>
                <div className="d-flex mt-2">
                  <p className="m-0 flex-grow-1 align-self-center">Start:</p> <input type="date"
                                                                                     className="form-control ml-2"
                                                                                     onChange={(e) => setStartDate(e.target.value)}/>
                </div>
                <div className="d-flex mt-2">
                  <p className="m-0 align-self-center">End:</p> <input type="date" className="form-control ml-3"
                                                                       onChange={(e) => setEndDate(e.target.value)}/>
                </div>
                {dateError &&
                  <div className="d-flex">
                    <p className="text-danger">Vul de datums in</p>
                  </div>}
              </div>
              <div className="col-md-4 mb-3">
                <a href="#" onClick={() => exportExamples('last_export')}>Export examples since last export</a>
              </div>
              <div className="col-md-4 mb-3">
                <a href="#" onClick={() => exportExamples('all')}>Export all examples</a>
              </div>
              <div className="col-md-4 mb-3">
                <a href="#" onClick={exportAllUsers}>Export all users</a>
              </div>
            </div>
          </div>
        </section>
      }
    </>
  )
});

export default Export;
