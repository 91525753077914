import { all, call, put, takeEvery } from 'redux-saga/effects';

import { toast } from "react-toastify";

import API from '../../utils/api';
import {
  addTeamFailure,
  addTeamSuccess,
  editTeamFailure,
  editTeamSuccess,
  teamListActiveSuccess,
  teamListFailure,
  teamListInActiveSuccess
} from '../action';
import { ADD_TEAM, EDIT_TEAM, TEAM_LIST, TEAM_SEARCH_LIST } from "../action/types";

function* teamListRequest(action) {
  try {
    const res = yield API.post('/team-list', action.payload)
    if (res.data.meta.code === 1) {
      if (action.payload.is_active === 1) {
        yield put(teamListActiveSuccess(res.data));
      } else {
        yield put(teamListInActiveSuccess(res.data));
      }
      
    } else if (res.data.meta.code === 0) {
      toast.error(res.data.meta.message);
      yield put(teamListFailure(res.data));
    }
  } catch (e) {
    yield put(teamListFailure(e));
  }
}

function* teamSearchListRequest(action) {
  try {
    const res = yield API.post('/team-search-list', action.payload)
    if (res.data.meta.code === 1) {
      if (action.payload.is_active === 1) {
        yield put(teamListActiveSuccess(res.data));
      } else {
        yield put(teamListInActiveSuccess(res.data));
      }
      
    } else if (res.data.meta.code === 0) {
      toast.error(res.data.meta.message);
      yield put(teamListFailure(res.data));
    }
  } catch (e) {
    yield put(teamListFailure(e));
  }
}

function* addTeamRequest(action) {
  try {
    const res = yield API.post('/team/add', action.payload.values)
    if (res.data.meta.code === 1) {
      yield put(addTeamSuccess(res.data.data));
      yield call(action.payload.callback)
    } else if (res.data.meta.code === 0) {
      toast.error(res.data.meta.message);
      yield put(addTeamFailure(res.data));
    }
  } catch (e) {
    yield put(addTeamFailure(e));
  }
}

function* editTeamRequest(action) {
  try {
    
    const formData = new FormData();
    formData.append('name', action.payload.values.name);
    formData.append('end_date', action.payload.values.end_date);
    formData.append('active', action.payload.values.active);
    formData.append('subscription', action.payload.values.subscription);
    if (action.payload.logo_url) {
      formData.append('logo', action.payload.logo_url);
    }
    
    const res = yield API.post('/team/edit/' + action.payload.id, formData)
    if (res.data.meta.code === 1) {
      yield put(editTeamSuccess(res.data));
      yield call(action.payload.callback);
    } else if (res.data.meta.code === 0) {
      toast.error(res.data.meta.message);
      yield put(editTeamFailure(res.data));
    }
  } catch (e) {
    yield put(editTeamFailure(e));
  }
}

export function* watchTeamListAPI() {
  yield takeEvery(TEAM_LIST, teamListRequest);
  yield takeEvery(TEAM_SEARCH_LIST, teamSearchListRequest);
  yield takeEvery(ADD_TEAM, addTeamRequest);
  yield takeEvery(EDIT_TEAM, editTeamRequest);
}

export default function* rootSaga() {
  yield all([
    watchTeamListAPI()
  ]);
}
