import {UPDATE_USER_DETAILS, UPDATE_USER_DETAILS_SUCCESS, UPDATE_USER_DETAILS_FAILURE,
PROFILE_DETAIL,PROFILE_DETAIL_FAILURE,PROFILE_DETAIL_SUCCESS,
UPDATE_ACCOUNT_DETAIL,UPDATE_ACCOUNT_DETAIL_SUCCESS,UPDATE_ACCOUNT_DETAIL_FAILURE,
CHANGE_PASSWORD,CHANGE_PASSWORD_SUCCESS,CHANGE_PASSWORD_FAILURE
} from "./types";


export const profileUser = (payload) => ({
    type: UPDATE_USER_DETAILS,
    payload
});

export const profileUserSuccess = () => ({
    type: UPDATE_USER_DETAILS_SUCCESS
});

export const profileUserFailure = () => ({
    type: UPDATE_USER_DETAILS_FAILURE,
});

export const profileDetail = (payload) =>({
    type: PROFILE_DETAIL,
    payload
});

export const profileDetailSuccess = (payload) =>({
   type: PROFILE_DETAIL_SUCCESS,
    payload
});

export const profileDetailFailure = () =>({
    type: PROFILE_DETAIL_FAILURE
});

export const updateAccountDetail = (payload) =>({
    type: UPDATE_ACCOUNT_DETAIL,
    payload
});

export const updateAccountSuccess = (payload) =>({
    type: UPDATE_ACCOUNT_DETAIL_SUCCESS,
    payload
});

export const updateAccountFailure = (payload) =>({
    type: UPDATE_ACCOUNT_DETAIL_FAILURE,
    payload
});

export const changePassword = (payload) =>({
    type: CHANGE_PASSWORD,
    payload
});

export const changePasswordSuccess = (payload) =>({
    type: CHANGE_PASSWORD_SUCCESS,
    payload
});

export const changePasswordFailure = (payload) =>({
    type: CHANGE_PASSWORD_FAILURE,
    payload
});