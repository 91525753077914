import { all, takeEvery, put } from 'redux-saga/effects';

import {toast} from "react-toastify";

import API from '../../utils/api';
import {addExampleDetailSuccess, addExampleDetailFailure } from '../action';
import {ADD_EXAMPLE_DETAIL} from "../action/types";

function* addEditExampleRequest(action) {
    try {

        const res = yield API.get(`example-detail/${action.payload.id}`)
        if(res.data.meta.code === 1){
            yield put(addExampleDetailSuccess(res.data.data));

        }else if(res.data.meta.code === 0){
            toast.error(res.data.meta.message);
            yield put(addExampleDetailFailure(res.data));
        }
    }catch (e) {
        yield put(addExampleDetailFailure(e));
    }
}

export function* watchAddEditDetailAPI() {
    yield takeEvery(ADD_EXAMPLE_DETAIL, addEditExampleRequest);
}

export default function* rootSaga() {
    yield all([
        watchAddEditDetailAPI()
    ]);
}
