import { all, takeEvery, put } from 'redux-saga/effects';
import {GET_HOME_LIST} from '../action/types';
import {getHomeDataSuccess, getHomeDataFailure} from '../action';
import API from '../../utils/api';

function* getHomeDataRequest() {
    try {
        const res = yield API.get('home');
        if(res.data.meta.code === 1){
            yield put(getHomeDataSuccess(res.data));
        }else if(res.data.meta.code === 0){
            yield put(getHomeDataFailure(res.data));
        }
    }catch (e) {
        yield put(getHomeDataFailure(e));
    }
}

export function* watchGetHomeDataAPI() {
    yield takeEvery(GET_HOME_LIST, getHomeDataRequest);
}

export default function* rootSaga() {
    yield all([
        watchGetHomeDataAPI()
    ]);
}
