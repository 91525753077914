import {
  EXAMPLE_DETAIL,
  EXAMPLE_DETAIL_FAILURE,
  EXAMPLE_DETAIL_SUCCESS,
  EXAMPLE_LIST,
  EXAMPLE_LIST_FAILURE,
  EXAMPLE_LIST_LOAD_MORE,
  EXAMPLE_LIST_SUCCESS,
  CHECK_EXAMPLE_LIST,
  CHECK_EXAMPLE_LIST_FAILURE,
  CHECK_EXAMPLE_LIST_LOAD_MORE,
  CHECK_EXAMPLE_LIST_SUCCESS,
  RESET_EXAMPLE_LIST,
  UPDATE_EXAMPLE_DETAIL,
  UPDATE_EXAMPLE_LIST, UPDATE_STATUS_EXAMPLE,
} from '../action/types';


const INIT_STATE = {
  loading: false,
  data: [],
  exampleDetailData: {},
  pagination: {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case EXAMPLE_LIST:
      return { ...state, loading: true };
    case EXAMPLE_LIST_SUCCESS:
      return { ...state, loading: false, data: action.payload.data, pagination: action.payload.meta };
    case    EXAMPLE_LIST_FAILURE:
      return { ...state, loading: false, data: action.payload };
    case EXAMPLE_LIST_LOAD_MORE:
      return { ...state, loading: false, data: state.data.concat(action.payload.data), pagination: action.payload.meta }
    case  UPDATE_EXAMPLE_LIST:
      return { ...state, loading: false, data: action.payload }
    case RESET_EXAMPLE_LIST: {
      return { ...state, loading: false, data: [] }
    }
    case EXAMPLE_DETAIL:
      return { ...state, loading: true };
    case EXAMPLE_DETAIL_SUCCESS:
      return { ...state, loading: false, exampleDetailData: action.payload };
    case  EXAMPLE_DETAIL_FAILURE:
      return { ...state, loading: false, exampleDetailData: action.payload };
    case  UPDATE_EXAMPLE_DETAIL:
      return { ...state, loading: false, exampleDetailData: action.payload }
    
    case CHECK_EXAMPLE_LIST:
      return { ...state, loading: true };
    case CHECK_EXAMPLE_LIST_SUCCESS:
      return { ...state, loading: false, data: action.payload.data, pagination: action.payload.meta };
    case CHECK_EXAMPLE_LIST_FAILURE:
      return { ...state, loading: false, data: action.payload };
    case CHECK_EXAMPLE_LIST_LOAD_MORE:
      return { ...state, loading: false, data: state.data.concat(action.payload.data), pagination: action.payload.meta }
    case  UPDATE_STATUS_EXAMPLE:
      return { ...state, loading: false, exampleDetailData: action.payload}
    
    default:
      return state;
  }
};
