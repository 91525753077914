
 import React from 'react'
const Loader = () => {

    return (
        <>
            <div className='text-center loader-main d-flex justify-content-center align-items-center'>
            <div className="loader d-inline-block">
                <div className="loader-head"></div>
            </div>

            </div>
        </>
    )
}

export default  Loader;