export const EMAIL_REQUIRED = 'Please enter your email.'
export const EMAIL_NOT_VALID = 'Please enter a valid email.'
export const EMAIL_MAX_CHAR = 'Email should not be longer than 50 chars.'

export const PASSWORD_REQUIRED = 'Please enter password.'
export const PASSWORD_VALID = 'Please enter a valid password.'
export const PASSWORD_MIN_CHAR = 'Password must be minimum 6 characters.'
export const PASSWORD_MAX_CHAR = 'Password must be maximum 15 characters.'
export const PASSWORD_DOES_NOT_MATCH = 'Password and Confirm Password does not match.'
export const CONFIRM_REQUIRED = 'Please enter confirm password.'

export const TEAM_NAME_REQUIRED = 'Team name can’t be blank.'
export const TEAM_NAME_MAX_CHAR = 'Team name should not be longer than 50 chars.'

export const FIRST_NAME_REQUIRED = 'First Name can’t be blank.'
export const FIRST_NAME_MAX_CHAR = 'First Name should not be longer than 100 chars.'

export const LAST_NAME_REQUIRED = 'Last Name can’t be blank.'
export const LAST_NAME_MAX_CHAR = 'Last Name should not be longer than 100 chars.'

export const INVALID_FILE_TYPE = 'This file is not supported. Please choose file with jpg, png format only.'
export const FILE_MAX_SIZE_MSG = 'Maximum 15 MB file size is allowed.'
export const FILE_MAX_SIZE = 15 * 1000 * 1024;
export const TITLE = 'Good job, we just need you to fill in a Title!';
export const PICTURE_A = 'Good job, we just need you to upload  Before image!'
export const PICTURE_B = 'Good job, we just need you to upload  After image!'
export const VISIBILITY = 'Good job, we just need to fill in a Visibility!'
export const BESTPRACTICE = 'Best practice can’t be blank.'
export const RESULT_PERCENTAGE = 'Good job, we just need to fill in a  Result percentage!'
export const RESULT_STATUS = 'Result status can’t be blank.'
export const VIDEODESCRIPTION = 'Video description should not be longer than 200 chars.'
export const SHORT_DESCRIPTION = 'Description should not be longer than 1000 chars.'
export const TESTCOMPANY = 'Good job, we just need to fill in a Tested company!'
export const TESTWITH = 'Testing Software can’t be blank.'
export const CATEGORYID = 'Category(s) can’t be blank.'
export const VIDEOURL = 'Good job, we just need to fill in a   Video URL is invalid!'

export const INVALID_CSV_FILE = 'Invalid file format. Please upload .csv file'

export const FILE_SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png"
];


export const validateImage = (file) => {
  const imageExtensions = 'jpg|png|jpeg';
  if (file) {
    let imageLowerCase;
    if (typeof file === 'string') { // For Update image
      imageLowerCase = file.toLowerCase();
    } else {     // For Add New  image
      imageLowerCase = file.name.toLowerCase();
    }
    const regex = new RegExp(`(.*?).(${imageExtensions})$`); // add or remove required extensions here
    const regexTest = regex.test(imageLowerCase);
    return !regexTest;
  }
  
}


export const COMMENT_REQUIRED = 'Comment can’t be blank'
export const COMMENT_MAX_CHAR = 'Comment should not be longer than 500 chars'
