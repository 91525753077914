import { all, takeEvery, put,call } from 'redux-saga/effects';

import {toast} from "react-toastify";

import API from '../../utils/api';
import {teamUserListSuccess, teamUserListFailure,shareUserSuccess,shareUserFailure } from '../action';
import {TEAM_USER_LIST,SHARE_EXAMPLE} from "../action/types";

function* teamListRequest() {
    try {

        const res = yield API.get('/team-user-list')
        if(res.data.meta.code === 1){
            yield put(teamUserListSuccess(res.data.data));

        }else if(res.data.meta.code === 0){
            toast.error(res.data.meta.message);
            yield put(teamUserListFailure(res.data));
        }
    }catch (e) {
        yield put(teamUserListFailure(e));
    }
}
function* shareUserRequest(action) {
    try {

        const res = yield API.post(`/share-example/${action.payload.id}`,{
            users_id: action.payload.users_id
        })
        if(res.data.meta.code === 1){
            yield put(shareUserSuccess(res.data.data));
            toast.success(res.data.meta.message);
            yield call(action.payload.callback)
            yield call(action.payload.callback1)
        }else if(res.data.meta.code === 0){
            toast.error(res.data.meta.message);
            yield put(shareUserFailure(res.data));
        }
    }catch (e) {
        yield put(shareUserFailure((e)));
    }
}

export function* watchTeamUserListAPI() {
    yield takeEvery(TEAM_USER_LIST, teamListRequest);
    yield takeEvery(SHARE_EXAMPLE, shareUserRequest);
}

export default function* rootSaga() {
    yield all([
        watchTeamUserListAPI()
    ]);
}
