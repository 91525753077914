import {
  CANCEL_INVITE,
  CANCEL_INVITE_FAILURE,
  CANCEL_INVITE_SUCCESS,
  INVITE_BULK,
  INVITE_BULK_FAILURE,
  INVITE_BULK_SUCCESS,
  RESET_INVITE,
  RESET_INVITE_FAILURE,
  RESET_INVITE_SUCCESS,
  UPDATE_LIST_PROFILE,
  UPDATE_USER_LIST_ACTIVE_SUCCESS,
  USER_INVITE,
  USER_INVITE_FAILURE,
  USER_INVITE_SUCCESS,
  USER_LIST,
  USER_LIST_ACTIVE_RESET,
  USER_LIST_ACTIVE_SUCCESS,
  USER_LIST_FAILURE,
  USER_LIST_INACTIVE,
  USER_LIST_INACTIVE_RESET,
  ALL_USER_LIST,
  ALL_USER_LIST_ACTIVE_RESET,
  ALL_USER_LIST_ACTIVE_SUCCESS,
  ALL_USER_LIST_FAILURE,
  ALL_USER_LIST_INACTIVE,
  ALL_USER_LIST_INACTIVE_RESET,
  USER_LIST_UPDATE_ACTIVE,
  USER_UPDATE,
  USER_UPDATE_FAILURE,
  USER_UPDATE_SUCCESS, SEARCH_USER_LIST, SEARCH_USER_LIST_TEAM
} from './types';

export const userList = (payload) => ({
  type: USER_LIST,
  payload,
});

export const userListActiveSuccess = (payload) => ({
  type: USER_LIST_ACTIVE_SUCCESS,
  payload,
});

export const userListFailure = () => ({
  type: USER_LIST_FAILURE,
});

export const userListInActiveSuccess = (payload) => ({
  type: USER_LIST_INACTIVE,
  payload,
});

export const searchUsers = (payload) => ({
  type: SEARCH_USER_LIST,
  payload,
});

export const searchUsersForTeam = (payload) => ({
  type: SEARCH_USER_LIST_TEAM,
  payload,
});

export const allUserList = (payload) => ({
  type: ALL_USER_LIST,
  payload,
});

export const allUserListActiveSuccess = (payload) => ({
  type: ALL_USER_LIST_ACTIVE_SUCCESS,
  payload,
});

export const allUserListFailure = () => ({
  type: ALL_USER_LIST_FAILURE,
});

export const allUserListInActiveSuccess = (payload) => ({
  type: ALL_USER_LIST_INACTIVE,
  payload,
});

export const resetAllUserActiveList = (payload) => ({
  type: ALL_USER_LIST_ACTIVE_RESET,
  payload
});

export const resetAllUserInctiveList = (payload) => ({
  type: ALL_USER_LIST_INACTIVE_RESET,
  payload
});

export const updateUserListActive = (payload) => ({
  type: USER_LIST_UPDATE_ACTIVE,
  payload
});
export const resetUserActiveList = (payload) => ({
  type: USER_LIST_ACTIVE_RESET,
  payload
});

export const resetUserInctiveList = (payload) => ({
  type: USER_LIST_INACTIVE_RESET,
  payload
});


export const updateListProfile = (payload) => ({
  type: UPDATE_LIST_PROFILE,
  payload
})

export const updateUser = (payload) => ({
  type: USER_UPDATE,
  payload
});
export const updateUserSuccess = (payload) => ({
  type: USER_UPDATE_SUCCESS,
  payload
});

export const updateUserFailure = (payload) => ({
  type: USER_UPDATE_FAILURE,
  payload
});

export const updateUserList = (payload) => ({
  type: UPDATE_USER_LIST_ACTIVE_SUCCESS,
  payload
});

export const cancelInviteUser = (payload) => ({
  type: CANCEL_INVITE,
  payload
});
export const cancelInviteSuccess = (payload) => ({
  type: CANCEL_INVITE_SUCCESS,
  payload
});

export const cancelInviteFailure = (payload) => ({
  type: CANCEL_INVITE_FAILURE,
  payload
});

export const inviteUser = (payload) => ({
  type: USER_INVITE,
  payload
});
export const inviteUserSuccess = (payload) => ({
  type: USER_INVITE_SUCCESS,
  payload
});
export const inviteUserFailure = (payload) => ({
  type: USER_INVITE_FAILURE,
  payload
});
export const resetInviteUser = (payload) => ({
  type: RESET_INVITE,
  payload
});
export const resetInviteUserSuccess = (payload) => ({
  type: RESET_INVITE_SUCCESS,
  payload
});

export const resetInviteUserFailure = (payload) => ({
  type: RESET_INVITE_FAILURE,
  payload
});

export const bulkInvite = (payload) => ({
  type: INVITE_BULK,
  payload
});

export const bulkInviteSuccess = (payload) => ({
  type: INVITE_BULK_SUCCESS,
  payload
});

export const bulkInviteFailure = (payload) => ({
  type: INVITE_BULK_FAILURE,
  payload
});


