import {
  ADD_TEAM,
  ADD_TEAM_FAILURE,
  ADD_TEAM_SUCCESS,
  TEAM_LIST,
  TEAM_LIST_ACTIVE_RESET,
  TEAM_LIST_ACTIVE_SUCCESS,
  TEAM_LIST_FAILURE,
  TEAM_LIST_INACTIVE_RESET,
  TEAM_LIST_INACTIVE_SUCCESS,
  TEAM_LIST_SUCCESS,
  TEAM_SEARCH_LIST,
  TEAM_USER_LIST,
  TEAM_USER_LIST_FAILURE,
  TEAM_USER_LIST_SUCCESS,
  EDIT_TEAM,
  EDIT_TEAM_FAILURE,
  EDIT_TEAM_SUCCESS,
  SHARE_EXAMPLE,
  SHARE_EXAMPLE_FAILURE,
  SHARE_EXAMPLE_SUCCESS,
  UPDATE_LIST_TEAM
} from "./types";


export const teamUserList = () => ({
  type: TEAM_USER_LIST
});

export const teamUserListSuccess = (payload) => ({
  type: TEAM_USER_LIST_SUCCESS,
  payload
});

export const teamUserListFailure = () => ({
  type: TEAM_USER_LIST_FAILURE,
});

export const teamList = (payload) => ({
  type: TEAM_LIST,
  payload
});

export const teamSearchList = (payload) => ({
  type: TEAM_SEARCH_LIST,
  payload
});

export const teamListSuccess = (payload) => ({
  type: TEAM_LIST_SUCCESS,
  payload
});

export const teamListActiveSuccess = (payload) => ({
  type: TEAM_LIST_ACTIVE_SUCCESS,
  payload
});

export const teamListInActiveSuccess = (payload) => ({
  type: TEAM_LIST_INACTIVE_SUCCESS,
  payload
});

export const resetTeamActiveList = (payload) => ({
  type: TEAM_LIST_ACTIVE_RESET,
  payload
});

export const resetTeamInctiveList = (payload) => ({
  type: TEAM_LIST_INACTIVE_RESET,
  payload
});

export const teamListFailure = () => ({
  type: TEAM_LIST_FAILURE,
});

export const updateTeamList = (payload) => ({
  type: UPDATE_LIST_TEAM,
  payload
})

export const addTeam = (payload) => ({
  type: ADD_TEAM,
  payload
});

export const addTeamSuccess = (payload) => ({
  type: ADD_TEAM_SUCCESS,
  payload
});

export const addTeamFailure = () => ({
  type: ADD_TEAM_FAILURE,
});


export const editTeam = (payload) => ({
  type: EDIT_TEAM,
  payload
});

export const editTeamSuccess = (payload) => ({
  type: EDIT_TEAM_SUCCESS,
  payload
});

export const editTeamFailure = () => ({
  type: EDIT_TEAM_FAILURE,
});


export const shareUser = (payload) => ({
  type: SHARE_EXAMPLE,
  payload
});

export const shareUserSuccess = (payload) => ({
  type: SHARE_EXAMPLE_SUCCESS,
  payload
});

export const shareUserFailure = (payload) => ({
  type: SHARE_EXAMPLE_FAILURE,
  payload
});
