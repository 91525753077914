import { all, call, put, takeEvery } from 'redux-saga/effects';

import { toast } from 'react-toastify';

import API from '../../utils/api';
import {
  changePasswordFailure,
  changePasswordSuccess,
  profileDetailFailure,
  profileDetailSuccess,
  profileUserFailure,
  profileUserSuccess,
  saveDeviceToken,
  updateAccountFailure,
  updateAccountSuccess,
  updateAuthStatus
} from '../action';
import { CHANGE_PASSWORD, PROFILE_DETAIL, UPDATE_ACCOUNT_DETAIL, UPDATE_USER_DETAILS } from '../action/types';
import { setUserdata } from '../../utils/helper';

function* updateProfileRequest(action) {
  try {
    const formData = new FormData();
    
    formData.append('first_name', action.payload.values.firstName);
    formData.append('last_name', action.payload.values.lastName);
    formData.append('accept_terms', action.payload.values.accept_terms);
    if (action.payload.userImage) {
      formData.append('profile_photo', action.payload.userImage);
    }
    const res = yield API.post('update-profile-details', formData);
    if (res.data.meta.code === 1) {
      if (action.payload.deviceToken) {
        yield put(saveDeviceToken({
          device_token: action.payload.deviceToken,
          is_logout: 0,
          callback: () => {
          }
        }))
      }
      yield put(updateAuthStatus(true));
      yield put(profileUserSuccess(res.data));
      toast.success(res.data.meta.message);
      yield call(setUserdata, res.data.data);
      yield call(action.payload.callback);
    } else if (res.data.meta.code === 0) {
      toast.error(res.data.meta.message);
      yield put(profileUserFailure(res.data));
    }
  } catch (e) {
    yield put(profileUserFailure(e));
  }
}

function* profileDetailsRequest() {
  try {
    const res = yield API.get('profile-details');
    if (res.data.meta.code === 1) {
      yield put(profileDetailSuccess(res.data.data));
    } else if (res.data.meta.code === 0) {
      toast.error(res.data.meta.message);
      yield put(profileDetailFailure(res.data));
    }
  } catch (e) {
    yield put(profileDetailFailure(e));
  }
}

function* updateAccountProfileRequest(action) {
  try {
    const formData = new FormData();
    
    formData.append('first_name', action.payload.values.firstName);
    formData.append('last_name', action.payload.values.lastName);
    if (action.payload.profileImage) {
      formData.append('profile_photo', action.payload.profileImage);
    }
    if (action.payload.companyImage) {
      formData.append('company_logo', action.payload.companyImage);
    }
    if (action.payload.type === 1) {
      formData.append('company_name', action.payload.values.teamName);
    }
    const res = yield API.post('update-account-details', formData);
    if (res.data.meta.code === 1) {
      yield put(updateAccountSuccess(res.data));
      yield call(setUserdata, res.data.data);
      toast.success(res.data.meta.message);
    } else if (res.data.meta.code === 0) {
      toast.error(res.data.meta.message);
      yield put(updateAccountFailure(res.data));
    }
  } catch (e) {
    yield put(updateAccountFailure(e));
  }
}

function* changePasswordRequest(action) {
  try {
    const formData = new FormData();
    
    formData.append('current_password', action.payload.values.currentPassword);
    formData.append('new_password', action.payload.values.newPassword);
    
    const res = yield API.post('/change-password', formData);
    if (res.data.meta.code === 1) {
      yield put(changePasswordSuccess(res.data));
      toast.success(res.data.meta.message);
      yield call(action.payload.callback)
      yield call(action.payload.callback1)
      yield call(action.payload.callback2)
    } else if (res.data.meta.code === 0) {
      toast.error(res.data.meta.message);
      yield put(changePasswordFailure(res.data));
    }
  } catch (e) {
    yield put(changePasswordFailure(e));
  }
}

export function* watchProfileUserAPI() {
  yield takeEvery(UPDATE_USER_DETAILS, updateProfileRequest);
  yield takeEvery(PROFILE_DETAIL, profileDetailsRequest);
  yield takeEvery(UPDATE_ACCOUNT_DETAIL, updateAccountProfileRequest);
  yield takeEvery(CHANGE_PASSWORD, changePasswordRequest);
}

export default function* rootSaga() {
  yield all([
    watchProfileUserAPI()
  ]);
}
