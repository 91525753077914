import {
    GET_HOME_LIST_SUCCESS,
    GET_HOME_LIST_FAILURE,
} from '../action/types';

const INIT_STATE = {
    data:{},
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_HOME_LIST_SUCCESS:
            return { ...state, data: action.payload};
        case GET_HOME_LIST_FAILURE:
            return { ...state, data: action.payload};
        default:
            return state;
    }
};
