import {UPDATE_USER_DETAILS, UPDATE_USER_DETAILS_SUCCESS,UPDATE_USER_DETAILS_FAILURE,
    PROFILE_DETAIL,PROFILE_DETAIL_SUCCESS,PROFILE_DETAIL_FAILURE,
    UPDATE_ACCOUNT_DETAIL,UPDATE_ACCOUNT_DETAIL_SUCCESS,UPDATE_ACCOUNT_DETAIL_FAILURE,
    CHANGE_PASSWORD,CHANGE_PASSWORD_SUCCESS,CHANGE_PASSWORD_FAILURE

} from "../action/types";


const INIT_STATE = {
    loading: false,
    data:{},
    profileData:{},
    updateProfileData:{}
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case UPDATE_USER_DETAILS:
            return { ...state, loading: true };
        case UPDATE_USER_DETAILS_SUCCESS:
            return { ...state, loading: false,data:action.payload };
        case    UPDATE_USER_DETAILS_FAILURE:
            return { ...state, loading: false };
        case PROFILE_DETAIL:
            return {...state,loading: true};
        case PROFILE_DETAIL_SUCCESS:
            return {...state,loading: false,profileData: action.payload};
        case PROFILE_DETAIL_FAILURE:
            return {...state,loading: false};
        case UPDATE_ACCOUNT_DETAIL:
            return {...state,loading: true};
        case UPDATE_ACCOUNT_DETAIL_SUCCESS:
            return {...state,loading: false,updateProfileData: action.payload};
        case UPDATE_ACCOUNT_DETAIL_FAILURE:
            return {...state,loading: false}
        case CHANGE_PASSWORD:
            return {...state,loading: true};
        case CHANGE_PASSWORD_SUCCESS:
            return {...state,data: action.payload,loading: false};
        case CHANGE_PASSWORD_FAILURE:
            return {...state,data: action.payload,loading: false}
        default:
            return state;
    }
};
