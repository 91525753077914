import { lazy } from 'react';
import Export from "../containers/Export/export";
import NoAccess from "../containers/NoAccess/NoAccess";

const Home = lazy(() => import('../containers/Home/Home'));
const Login = lazy(() => import('../containers/LRF/Login'));
const ForgotPassword = lazy(() => import('../containers/LRF/ForgotPassword'));
const ResetPassword = lazy(() => import('../containers/LRF/ResetPassword'));
const ViewExample = lazy(() => import('../containers/ViewExample/ViewExample'))
const AddExample = lazy(() => import('../containers/Example/addExample'))
const ThankYouPage = lazy(() => import('../containers/Example/ThankYouPage'))
const MyExamples = lazy(() => import('../containers/OverviewExample/myExamples'))
const TeamExamples = lazy(() => import('../containers/OverviewExample/teamExamples'))
const AllExamples = lazy(() => import('../containers/OverviewExample/allExamples'))
const CheckExamples = lazy(() => import('../containers/OverviewExample/checkExamples'))
const CheckViewExample = lazy(() => import('../containers/OverviewExample/checkViewExample'))
const InstructionVideos = lazy(() => import('../containers/instructionvideos'))
const EditExample = lazy(() => import('../containers/Example/editExample'))
const InviteUser = lazy(() => import("../containers/Users/InviteUser"))
const Inbox = lazy(() => import('../containers/Inbox/Inbox'))
const Account = lazy(() => import('../containers/Account/Account'))
const ManageUsers = lazy(() => import('../containers/Users/ManageUsers'))
const ManageAllUsers = lazy(() => import('../containers/Users/ManageAllUsers'))
const ManageTeam = lazy(() => import('../containers/Team/ManageTeam'))
const InviteTeam = lazy(() => import('../containers/Team/InviteTeam'))

const routes = [
  {
    path: '/',
    exact: true,
    name: 'Home',
    component: Home,
    private: true,
  },
  {
    path: '/login',
    exact: true,
    name: 'Login',
    component: Login,
    private: false,
  },
  {
    path: '/forgot-password',
    exact: true,
    name: 'Forgot Password',
    component: ForgotPassword,
    private: false
  },
  {
    path: '/reset-password',
    exact: true,
    name: 'Reset Password',
    component: ResetPassword,
    private: false
  },
  {
    path: '/example',
    name: 'AddExample',
    component: AddExample,
    private: true
  },
  {
    path: '/thankyou',
    name: 'ThankYouPage',
    component: ThankYouPage,
    private: true
  },
  {
    path: '/my-examples',
    name: 'MyExamples',
    component: MyExamples,
    private: true
  },
  {
    path: '/no-access',
    name: 'NoAccess',
    component: NoAccess,
    private: true
  },
  {
    path: '/all-examples',
    name: 'AllExamples',
    component: AllExamples,
    private: true
  },
  {
    path: '/team-examples',
    name: 'TeamExamples',
    component: TeamExamples,
    private: true
  },
  {
    path: '/check-examples',
    name: 'CheckExamples',
    component: CheckExamples,
    private: true
  },
  
  {
    path: '/check-view-example/:id',
    exact: true,
    name: 'Check View Example',
    component: CheckViewExample,
    private: true
  },
  {
    path: '/view-example/:id',
    exact: true,
    name: 'View Example',
    component: ViewExample,
    private: true
  },
  {
    path: '/edit-example/:id',
    name: 'EditExample',
    component: EditExample,
    private: true
  },
  {
    path: '/invite-user',
    name: 'InviteUser',
    component: InviteUser,
    private: true
  },
  {
    path: '/manage-users',
    name: 'ManageUsers',
    component: ManageUsers,
    private: true
  },
  {
    path: '/manage-all-users',
    name: 'ManageAllUsers',
    component: ManageAllUsers,
    private: true
  },
  {
    path: '/export',
    name: 'Export',
    component: Export,
    private: true
  },
  {
    path: '/invite-team',
    name: 'InviteTeam',
    component: InviteTeam,
    private: true
  },
  {
    path: '/manage-teams',
    name: 'ManageTeam',
    component: ManageTeam,
    private: true
  },
  {
    path: '/inbox',
    name: 'Inbox',
    component: Inbox,
    private: true
  }, {
    path: '/instructionvideos',
    name: 'Instruction Videos',
    component: InstructionVideos,
    private: true
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    private: true
  },
];

export default routes;
