import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import successPage from '../../assets/images/successImage.svg';
import cloudUploadIcon from "../../assets/images/cloud-upload-icon.svg";

const NoAccess = memo((props) => {
  const history = useHistory();
  const notificationCount = useSelector(state => state.notificationReducer.count)
  
  return (
    <>
      <section className='example-banner py-5'>
        <div className='container pt-5'>
          <div className='row align-items-center justify-content-between pt-md-5 mb-md-3'>
            <div className='col-12 col-md-8 col-lg-7 text-white'>
              <h2 className='c-topol-title'>Oh no ... you currently don't have access to our A/B Test database</h2>
              <p className='f-18 my-3 f-w-reg py-4'>
                If there has been a payment issue, or you have any questions please reach out to <a style={{color: 'white', textDecoration: "underline"}} href="mailto:database@onlineinfluence.com">support</a>
              </p>
            </div>
          </div>
        </div>
        <div className='clip-banner'/>
      </section>
      <section className='pb-5 mb-5'>
        <div className='container'>
          <div className='row  justify-content-between mt-5 text-center'>
            <div className='col-12'>
              <h2 className='f-54 mb-3 text-primary-grey f-topol-bol'>
                {notificationCount && notificationCount.user_type === 1 ? "Don't worry, your examples are safe." : 'Please ask your owner to renew your account'}
              </h2>
                <p className='f-18 m-0 f-w-reg pb-4 text-primary-grey'>
                  Your examples are not deleted, when you have access to the database they will become visible again.
                </p>
              {notificationCount && notificationCount.user_type === 1 &&
                <div className="w-100 d-flex justify-content-center flex-column">
                  <div className="d-inline align-items-center">
                    <svg className="emptyFill mr-2" width="30" height="30"  fill="none" stroke="green" viewBox="0 0 24 24"
                         xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    Access the database with {notificationCount.total_examples} examples
                  </div>
                  <div className="d-inline align-items-center">
                    <svg className="emptyFill mr-2" width="30" height="30"  fill="none" stroke="green" viewBox="0 0 24 24"
                         xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    Give your teammembers and yourself access again
                  </div>
                  <div className="d-inline align-items-center">
                    <svg className="emptyFill mr-2" width="30" height="30"  fill="none" stroke="green" viewBox="0 0 24 24"
                         xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    Don't miss out on new examples
                  </div>
                    <a
                      target="_blank"
                      href="https://onlineinfluence.com/ab-test-database"
                      className="c-btn c-btn-auto-height c-btn-link align-self-center mt-3"
                    >
                      View memberships
                    </a>
                  </div>
                }
            </div>
          </div>
        </div>
      </section>
    </>
  )
});

export default NoAccess;
